import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
   
   <div className="team"><br/>
        <p style={{textAlign:'center', fontWeight:'bold'}}>WILUMINATY TV is developed by WILUMINATY RESEARCH TEAM.</p>
        <div className="team-members">
        <div className="team-member">
            <img src="/Images/abhinayesir.jpeg" alt="Abhinaye Kareshiya" className="team-member-pic" />
            <p>
              <a style={{color:'white'}} href="https://www.linkedin.com/in/chanchal-panjabi" target="_blank" rel="noopener noreferrer">
              FOUNDER <br/>
              Abhinaye Kareshiya
              </a>
            </p>
          </div>
          <div className="team-member">
            <img src="/Images/chanchal.jpeg" alt="Chanchal Panjabi" className="team-member-pic" />
            <p>
              <a style={{color:'white'}} href="https://www.linkedin.com/in/chanchal-panjabi" target="_blank" rel="noopener noreferrer">
              DEVELOPER <br/>
                Chanchal Panjabi
              </a>
            </p>
          </div>
          <div className="team-member">
            <img src="/Images/himanshuprofile.jpeg" alt="Himanshu Chandak" className="team-member-pic" />
            <p>
              <a style={{color:'white'}} href="https://www.linkedin.com/in/himanshu-chandak-53a55222b" target="_blank" rel="noopener noreferrer">
              DEVELOPER <br/>
                Himanshu Chandak
              </a>
            </p>
          </div>
        </div>
      </div>
        
      <div className="footer-content">
        <div className="footer-column-text">
          <h3 >WiluminatyTV+</h3>
          <p>
            Established as a lifestyle brand, we, Wiluminaty Lifestyle Private
            Limited, situated at Indora, Nagpur, Maharashtra have a wide range of
            clothing and bags collection. Imbibing the spontaneous, vibrant &
            independent nature of the 21st century.
          </p>
        </div>
        <div className="usefullinks">
          <h3 style={{marginLeft:'1em' }}>USEFUL LINKS</h3>
          <ul>
            <li>
              <a href="/donation">DONATION</a>
            </li>
            <li>
              <a href="/subscription">SUBSCRIPTION</a>
            </li>
            <li>
              <a href="/sponsorship">SPONSORSHIP</a>
            </li>
            <li>
              <a href="/investment">INVESTMENT</a>
            </li> 
            <li>
              <Link to="/career">CAREER</Link>
            </li>
            <li>
              <a href="/help">Help</a>
            </li>
          </ul>
        </div>
        <div className="usefullinksnew-text">
          <ul>
            <li>
              <a href="/termsanduse">TERMS OF USE</a>
            </li>
            <li>
              <a href="/privacypolicy">PRIVACY POLICY </a>
            </li>  
            <li>
              <a href="/exchangepolicy">EXCHANGE POLICY </a>
            </li>  
            <li>
              <a href="/shipping">SHIPPING</a>
            </li>  
            <li>
              <a href="/inviteandearn">INVITE & EARN </a>
            </li>  
            <li>
              <a href="/paymentoption">PAYMENT OPTION</a>
            </li>  
            <li>
              <a href="/promocode">PROMO CODE T&Cs</a>
            </li> 
           
          </ul>
        </div>
      
        <div className="segment-text">
          <h3 style={{marginLeft:'1em'}}>SEGMENT</h3>
          <ul>
            <li>
              <a href="/Magazine">MAGAZINE</a>
            </li>
            <li>
              <a href="/newsarticle">NEWS AND ARTICLE</a>
            </li>
            <li>
              <a href="/channel">CHANNEL</a>
            </li>
            <li>
              <Link to="/model">MODELS</Link>
            </li>
            <li>
              <a href="/photographer">PHOTOGRAPHER</a>
            </li>
            <li>
              <Link to="/designer">DESIGNER</Link>
            </li>
            <li>
              <Link to="/hairstylist">HAIR STYLIST</Link>
            </li>
            <li>
              <Link to="/product">PRODUCT</Link>
            </li>
            <li>
              <Link to="/monthstopper">MONTH'S TOPPER</Link>
            </li>
            <li>
              <Link to="/top10">TOP 10</Link>
            </li>
            <li>
              <Link to="/top100">TOP 100</Link>
            </li>
            <li>
              <Link to="/worldrecord">WORLD RECORD</Link>
            </li>
            <li>
              <Link to="/survey">SURVEY</Link>
            </li>
          </ul>
        </div>
        <div className="followus-text">
          <h3 style={{marginLeft:'2em'}}>FOLLOW US </h3>
          <ul>
            <li>
          <a href="https://www.facebook.com/wiluminaty">
            <img src="/Images/facebook.png" className="socialmediaicons"></img>
          </a>
          </li>
          <li>
          <a href="https://www.instagram.com/wiluminatylifestyle/">
          <img src="/Images/instagram.png" className="socialmediaicons"></img>
          </a>
          </li>
          <li>
          <a href="https://twitter.com/wiluminaty">
          <img src="/Images/twitter.png" className="socialmediaicons"></img>
          </a>
          </li>
          
          <li>
          <a href="https://whatsapp.com/channel/0029VaA23l70LKZ5eWb9kw2C">
          <img src="/Images/whatsapp.png" className="socialmediaicons"></img>
          </a>
          </li>
          <li>
          <a href="https://t.me/wiluminatytv">
          <img src="/Images/telegram.png" className="socialmediaicons"></img>
          </a>
          </li>
          <li>
          <a href="https://youtube.com/@wiluminatytv?si=bxowydNSqABLgnXU">
          <img src="/Images/youtube.png" className="socialmediaicons"></img>
          </a>
          </li>
          <li>
          <a href="https://www.linkedin.com/company/wiluminaty">
          <img src="/Images/linkedin.png" className="socialmediaicons"></img>
          </a>
          </li>
          
          </ul>
        </div>
        
        <div className="footer-column">
          <h3 style={{ textAlign: 'center'}}>CONTACT</h3>
          <ul>
            <li>
              <img src="/Images/home.jfif" className="contacticons"></img> Nagpur, Maharashtra
            </li>
            <li>
              <img src="/Images/email.jfif" className="contacticons"></img> wiluminatymedia@gmail.com
            </li>
            <li>
              <img src="/Images/contact.jfif"   className="contacticons"></img> +91-7276961619
            </li>
          </ul>
        </div>
      </div>
      
      <div className="footer-copyright">
        <p>&copy; 2024 WILUMINATY Website. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
