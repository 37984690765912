import React, { useState } from 'react';
import styled from 'styled-components';
import './Model.css';
import axios from 'axios';

// Styled components
const Container = styled.div`
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  margin-top: 7em;
`;

const Button = styled.button`
  padding: 1em 2em;
  margin-top: 1em;
  background-color: #cc5500;
  color: white;
  border: none;
  border-radius: 49px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darkred;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #333;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #e91e63;
`;

const HeroSection = styled.div`
  background: url('/path-to-your-image.jpg') no-repeat center center;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const HeroContent = styled.div`
  text-align: center;
  position: relative;
  z-index: 2;
`;

const TopFeaturedSection = styled.div`
  padding: 40px 20px;
  background-color: #111;
`;

const TopFeaturedTitle = styled.h2`
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
  color: white;
`;

const FeaturedModels = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ModelCard = styled.div`
  text-align: center;
  width: 200px;
  color: #fff;
`;

const ModelImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const FormContainer = styled.div`
  background-color: #222;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
`;

const TextArea = styled.textarea`
  width: 95%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
`;

const Select = styled.select`
  width: 95%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxLabel = styled.label`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  margin-right: 10px;
`;

const SubmitButton = styled(Button)`
  background-color: #e91e63;

  &:hover {
    background-color: #c2185b;
  }
`;

const Model = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    axios.post('http://localhost:3001/contact', formData)
      .then(response => {
        setMessage('Form submitted successfully!');
      })
      .catch(error => {
        console.error('There was an error submitting the form!', error);
        setMessage('Error submitting the form.');
      });
  };

  return (
    <div className='model-container' style={{ backgroundImage: 'url(/Images/orange1.jpg)', backgroundSize: 'cover', backgroundPosition: 'center', padding: '20px' }}>
      <Container>
        <Header>
          <Logo>Models</Logo>
        </Header>
        <h1 style={{ marginLeft: '29em', marginTop: '5em', position: 'absolute' }}>Wealth of Talent!</h1>
        <HeroSection>
          <HeroContent>
            <ModelImage src="/Images/abhinaye.jfif" alt="Model" />
          </HeroContent>
        </HeroSection>
        <TopFeaturedSection>
          <TopFeaturedTitle>Top Featured</TopFeaturedTitle>
          <h2 style={{ textAlign: 'center', color: 'white' }}> COMING Soon</h2>
          <FeaturedModels>
            <ModelCard>
              {/* <ModelImage src="/Images/model1.jpg" alt="Model 1" />
              <ModelName>ALEXIS MOORE</ModelName>
              <ModelDescription>An experienced model known for her versatility.</ModelDescription>
            </ModelCard>
            <ModelCard>
              <ModelImage src="/Images/model2.jpg" alt="Model 2" />
              <ModelName>JESSICA ALBA</ModelName>
              <ModelDescription>Renowned for her beauty and talent.</ModelDescription>
            </ModelCard>
            <ModelCard>
              <ModelImage src="/Images/model3.jpg" alt="Model 3" />
              <ModelName>KATE MADISON</ModelName>
              <ModelDescription>A rising star in the modeling industry.</ModelDescription>
            </ModelCard>
            <ModelCard>
              <ModelImage src="/Images/model4.jpg" alt="Model 4" />
              <ModelName>GEORGE SMITH</ModelName>
              <ModelDescription>Known for his charismatic presence.</ModelDescription> */}
            </ModelCard>
          </FeaturedModels>
          <p style={{ textAlign: 'center' }}>
            If you crave challenging work, ongoing opportunity for career growth, and rewards that make it all worthwhile, Lear has the job you are looking for. &nbsp;&nbsp;
            <Button style={{ textAlign: 'center' }} onClick={() => setShowForm(!showForm)}>Model Contact Information Form</Button>
          </p>
          {showForm && (
            <FormContainer>
              <h2>Contact Information Form</h2>
              <form onSubmit={handleFormSubmit}>
                <FormGrid>
                  <div>
                    <label>
                      First Name:
                      <Input type="text" name="firstName" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Last Name:
                      <Input type="text" name="lastName" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Email:
                      <Input type="email" name="email" onChange={handleChange} required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Gender:
                      <Select name="gender" onChange={handleChange}  required>
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </Select>
                    </label>
                  </div>
                  <div>
                    <label>
                      Date of Birth:
                      <Input type="date" name="dob" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Contact Number:
                      <Input type="text" name="contactNumber" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Communication Address:
                      <TextArea name="communicationAddress" rows="2"  onChange={handleChange}  required></TextArea>
                    </label>
                  </div>
                  <div>
                    <label>
                      Instagram / Facebook / Other profile on social media:
                      <Input type="text" name="socialMediaProfile" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Body Type:
                      <Input type="text" name="bodyType" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Height:
                      <Input type="text" name="height"  onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Weight:
                      <Input type="text" name="weight"  onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Hair Colour:
                      <Input type="text" name="hairColour"  onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Eye Colour:
                      <Input type="text" name="eyeColour"  onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Shoe Size:
                      <Input type="text" name="shoeSize"   onChange={handleChange} required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Chest / Bust:
                      <Input type="text" name="chestBust"   onChange={handleChange} required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Cup:
                      <Input type="text" name="cup"   onChange={handleChange} required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Hips:
                      <Input type="text" name="hips"   onChange={handleChange} required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Waist:
                      <Input type="text" name="waist" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Describe Yourself - Personality:
                      <TextArea name="describeYourself" rows="2"  onChange={handleChange} required></TextArea>
                    </label>
                  </div>
                  <div>
                    <label>
                      Modeling Type:
                      <Input type="text" name="modelingType" onChange={handleChange}  required />
                    </label>
                  </div>
                  <div>
                    <label>
                      Interest or other information relevant to the shoot:
                      <TextArea name="interestInfo" rows="2"  onChange={handleChange} required></TextArea>
                    </label>
                  </div>
                  <div>
                    <label>
                      For What type of images are you comfortable modeling? *
                      <CheckboxGroup>
                        <CheckboxLabel>
                          <CheckboxInput type="checkbox" name="comfortableImages" value="Portrait / Fashion" />
                          Portrait / Fashion
                        </CheckboxLabel>
                        <CheckboxLabel>
                          <CheckboxInput type="checkbox" name="comfortableImages" value="Glamour / Lingerie" />
                          Glamour / Lingerie
                        </CheckboxLabel>
                        <CheckboxLabel>
                          <CheckboxInput type="checkbox" name="comfortableImages" value="Transparent Dress" />
                          Transparent Dress
                        </CheckboxLabel>
                        <CheckboxLabel>
                          <CheckboxInput type="checkbox" name="comfortableImages" value="Partial Nudity" />
                          Partial Nudity
                        </CheckboxLabel>
                        <CheckboxLabel>
                          <CheckboxInput type="checkbox" name="comfortableImages" value="Topless" />
                          Topless
                        </CheckboxLabel>
                        <CheckboxLabel>
                          <CheckboxInput type="checkbox" name="comfortableImages" value="Artistic Nude" />
                          Artistic Nude
                        </CheckboxLabel>
                      </CheckboxGroup>
                    </label>
                  </div>
                  <div>
                    <label>
                      Do you have any Tattoo / piercing / other scars or marks that should be considered while planning to shoot? If yes Describe. *
                      <TextArea name="tattooPiercing" rows="2" onChange={handleChange}  required></TextArea>
                    </label>
                  </div>
                </FormGrid>
                <SubmitButton type="submit">Submit</SubmitButton>
              </form>
              {message && <p>{message}</p>}
            </FormContainer>
          )}
        </TopFeaturedSection>
      </Container>
    </div>
  );
};

export default Model;
