import React from 'react';
import './Top100.css';

const Top100 = () => {
    const backgroundImageStyle = {
        backgroundImage: 'url(/Images/orange1.jpg)',
       
            backgroundSize: 'cover',
            
            padding: '20px',
      
      };
  return (
    <div className="top100commingsoon" style={backgroundImageStyle}>
     
<img src='/Images/commingsoon.png'></img>
 


</div>

  );
};

export default Top100;
