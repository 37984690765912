import React from 'react';
import './PaymentOption.css';

const PaymentOptions = () => {
    const backgroundImageStyle = {
        backgroundImage: 'url(/Images/orange1.jpg)',
       
            backgroundSize: 'cover',
            
            padding: '20px',
      
      };
  return (
    <div className="payment-options" style={backgroundImageStyle}>
      <h1 style={{marginTop:'6em'}}>Payment Options</h1>
      <ul>
        <li>Credit Card</li>
        <li>Debit Card</li>
        <li>Internet Banking</li>
        <li>UPI</li>
        <li>COD (Cash on Delivery)</li>
      </ul>

      <h2>Delivery</h2>
      <p>
        The orders will normally be shipped within 3 working days from the date of order placed and delivery would be made to the customers within 7 working days from the date of order placed to serviceable locations. For non-serviceable locations by our official courier, the delivery would be made either through third party courier / Post and will take 10 - 12 working days for delivery.
      </p>

      <h2>Ordering Subsequent Cancellation</h2>
      <p>
        As long as the package has not left our warehouse, the order may be cancelled. Cancellations can be carried out by contacting our Customer Service on telephone 0712-6555559. If the package has already left our warehouse, we won’t be able to cancel the order.
      </p>

      <h2>Making Subsequent Amendments to an Order</h2>
      <p>
        It is not possible to subsequently change sizes or colours of items, or to add or delete items.
      </p>

      <h2>Telephone Orders/Ordering by Email/Post/Fax</h2>
      <p>
        Placing a telephone order or ordering by email/post/fax is not possible.
      </p>

      <h2>Exchanging Products</h2>
      <p>
        Exchanging products is not possible. Please ensure all details are entered correctly like size, color, etc. for the products that you are purchasing. Please enter a new order in the WILUMINATY TV India Online Shop.
      </p>

      <h2>Returning Items</h2>
      <p>Returns and Refunds are not applicable on discounted merchandise.</p>

      <h2>Deadline for Returns</h2>
      <p>
        You may return all items purchased in the WILUMINATY TV India Online Shop within 10 days of receipt of the merchandise. To place an order for return please call our customer care number at 0712-6555559. Bear in mind that it is only possible for us to accept items that are returned in their original state. The merchandise should be in an unused condition. The same conditions apply to reduced price items.
      </p>

      <h2>Address for Sending the Goods</h2>
      <p>
        You can send across the goods through any reputed courier agencies to the below address:
      </p>
      <address>
        A4/13, Vyankatesh Nagar,<br />
        Near K.D.K. College,<br />
        Nandanvan, Nagpur-440009
      </address>

      <h2>Reimbursement of Merchandise Value</h2>
      <p>
        Reimbursement is normally completed within 7 to 25 working days after we have received the returned merchandise. The returned goods should be in their original state and should not show any signs of wear.
      </p>
      <p>
        For Cash on Delivery transactions a cheque will be issued & dispatched in the name & address of the person who has ordered, within 5-7 business days of the receipt of the return order. The issuance of the cheque is subject to a thorough quality check of the returned merchandise to determine whether it is returned as per the requisite terms & conditions.
      </p>
      <p>
        For Prepaid orders (Credit/Debit Card, Net Banking), the transaction corresponding to the order will be reversed, if it is within 90 days of the original transaction date of the return order, into the Credit/Debit Card or Bank account which was used by the customer to place the order, within 8-10 working days receipt of the return order. The reversal of the transaction is subject to a thorough quality check of the returned merchandise to determine whether it is returned as per the requisite terms & conditions. In case the refund has to be made beyond 90 days of the original transaction date, a cheque will be issued & dispatched in the name & address of the person who has ordered.
      </p>

      <h2>Items Purchased from Stores</h2>
      <p>
        Please understand that we are only able to accept items purchased in our WILUMINATY TV INDIA Online Shop.
      </p>

      <h2>Guarantee</h2>
      <p>
        We extend a guarantee of 3 months on all items.
      </p>

      <h2>Trying the Items on</h2>
      <p>
        The merchandise may not show signs of wear. Please ensure that when trying shoes on, you only walk on a carpet or other dirt-free surface. In the event of the merchandise showing wear, compensation may be demanded.
      </p>

      <h2>Claims</h2>
      <p>
        If an item purchased in the WILUMINATY TV Online Shop does not meet WILUMINATY TV quality standard, you are entitled to make a claim within the legally applicable guarantee period. This also applies to items that have been reduced in price.
      </p>
      <p>
        Please send the merchandise you wish to make a claim about and enclose a copy of the invoice on which you have listed the quality issue, with one of the reputed courier agencies, to the address below:
      </p>
      <address>
        cs@wiluminatytv.com
      </address>
      <p>
        Claims can only be accepted for items purchased in the WILUMINATY TV Online Shop.
      </p>
    </div>
  );
};

export default PaymentOptions;
