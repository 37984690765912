import React from 'react';
import './Survey.css';

const SurveyForm = () => {
  const backgroundStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
       
    backgroundSize: 'cover',
    
    padding: '20px',
  };

  const formGroupStyle = {
    marginBottom: '15px',
  };

  return (
    <div className='survey-form' style={backgroundStyle} >
    <div className="survey-form-container">
      <h2 style={{marginTop:'2em'}}> Tell us about Fashion </h2>
      <form className='surveyform'>
        <div style={formGroupStyle} className="form-group">
          <label>Fashion to you is:</label>
          <ul>
            <li><input type="radio" name="fashion" value="Looking cool and hip" /> Looking cool and hip</li>
            <li><input type="radio" name="fashion" value="Looking sober and mature" /> Looking sober and mature</li>
            <li><input type="radio" name="fashion" value="Conformability" /> Conformability</li>
            <li><input type="radio" name="fashion" value="A way to express my inner self" /> A way to express my inner self</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Your favorite fabric:</label>
          <ul>
            <li><input type="radio" name="fabric" value="Cotton" /> Cotton</li>
            <li><input type="radio" name="fabric" value="Lycra" /> Lycra</li>
            <li><input type="radio" name="fabric" value="Jeans" /> Jeans</li>
            <li><input type="radio" name="fabric" value="Silk" /> Silk</li>
            <li><input type="radio" name="fabric" value="Linen" /> Linen</li>
            <li><input type="radio" name="fabric" value="Other" /> Other: <input type="text" name="otherFabric" /></li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Your favorite color is:</label>
          <input type="text" name="favoriteColor" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Before buying a garment, do you judge if it will suit on you or not?</label>
          <ul>
            <li><input type="radio" name="judgeBeforeBuying" value="Yes" /> Yes</li>
            <li><input type="radio" name="judgeBeforeBuying" value="No" /> No</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Do you buy clothes considering your body shape?</label>
          <ul>
            <li><input type="radio" name="considerBodyShape" value="Yes" /> Yes</li>
            <li><input type="radio" name="considerBodyShape" value="No" /> No</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Have you ever bought clothes out of impulse and later regretted?</label>
          <ul>
            <li><input type="radio" name="impulseBuying" value="Yes" /> Yes</li>
            <li><input type="radio" name="impulseBuying" value="No" /> No</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Your favorite designer?</label>
          <input type="text" name="favoriteDesigner" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Who is your fashion icon?</label>
          <input type="text" name="fashionIcon" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Are you affected by fashion trends by celebrities/models?</label>
          <ul>
            <li><input type="radio" name="affectedByTrends" value="Yes" /> Yes</li>
            <li><input type="radio" name="affectedByTrends" value="No" /> No</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>What is the fashion style you like the most?</label>
          <input type="text" name="likedFashionStyle" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>What is the fashion style you dislike the most?</label>
          <input type="text" name="dislikedFashionStyle" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Do you also buy accessories, shoes and makeup matching up to your clothes?</label>
          <ul>
            <li><input type="radio" name="buyAccessories" value="Yes" /> Yes</li>
            <li><input type="radio" name="buyAccessories" value="No" /> No</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>How much do you spend on buying clothes monthly or annually?</label>
          <input type="text" name="spendOnClothes" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Which are the places you buy most of the clothes from?</label>
          <ul>
            <li><input type="checkbox" name="placesToBuy" value="Super markets and shopping malls" /> Super markets and shopping malls</li>
            <li><input type="checkbox" name="placesToBuy" value="Designer boutiques" /> Designer boutiques</li>
            <li><input type="checkbox" name="placesToBuy" value="Fashion shows and exhibitions" /> Fashion shows and exhibitions</li>
            <li><input type="checkbox" name="placesToBuy" value="Branded retail outlets" /> Branded retail outlets</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Do your friends complement your fashion style?</label>
          <ul>
            <li><input type="radio" name="friendsComplement" value="Yes" /> Yes</li>
            <li><input type="radio" name="friendsComplement" value="No" /> No</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Do you shop for clothes during festivals and occasions or all year round or both?</label>
          <ul>
            <li><input type="radio" name="shopDuring" value="Festivals and occasions" /> Festivals and occasions</li>
            <li><input type="radio" name="shopDuring" value="All year round" /> All year round</li>
            <li><input type="radio" name="shopDuring" value="Both" /> Both</li>
          </ul>
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>What according to you should always be there in every man's wardrobe?</label>
          <input type="text" name="mensWardrobe" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>What according to you should always be there in every woman's wardrobe?</label>
          <input type="text" name="womensWardrobe" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Ideal look of a man according to you:</label>
          <input type="text" name="idealManLook" />
        </div>

        <div style={formGroupStyle} className="form-group">
          <label>Ideal look of a woman according to you:</label>
          <input type="text" name="idealWomanLook" />
        </div>

        <div>
          <button  className= 'buttonsubmitsurvey' type="submit">Submit</button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default SurveyForm;
