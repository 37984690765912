import React, { useState } from "react";
import "./SocialMedia.css";
import styled from "styled-components";


const companyWebsiteData = {
  platform: "Company Website",
  icon: "globe",
  color: "#4CAF50",
  link: "http://www.wiluminaty.in/search?srchval=wiluminaty&city=Nagpur",
};


const eComPlatformData = [
  {
    platform: "Amazon-Wiluminaty",
    icon: "amazon",
    color: "#FF9900",
    link: "https://bit.ly/Amazon-Wiluminaty",
  },
  {
    platform: "Flipkart-Wiluminaty",
    icon: "/Images/flipkart.jpg",
    color: "#F7E200",
    link: "https://bit.ly/Flipkart-Wiluminaty",
  },
  {
    platform: "JioMart-Wiluminaty",
    icon: "/Images/jiomart.jpg",
    color: "#cc0000",
    link: "https://bit.ly/JioMart-Wiluminaty",
  },
  
];
const EComPlatformItem = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: ${(props) => props.borderRadius};
  margin: 10px 0;
  padding: 10px;
  width: 300px;
  color: white;
  font-size: 18px;
`;
const CompanyWebsiteItem = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 50%;
      position: absolute;
    margin-left: 49em;
    margin-top: -26em;

  padding: 10px;
  width: 300px;
  color: white;
  font-size: 18px;
`;

const CustomIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const Icon = styled.i`
  font-size: 24px;
  margin-right: 10px;
`;

const isImageUrl = (url) => {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
};


const SocialMedia = () => {
  const backgroundStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
       
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '20px',
      
  };

  return (
    <div className="socialmedia" style={backgroundStyle}>
      <div className="social-media-container">
        <h2 className="animationheading">
          Find Us On Social Media Platform, E-commerce Platform, Company Website{" "}
        </h2>
        <br />
        <br />
        <br />
        <h1
          style={{
            textAlign: "left",
            marginLeft: "1em",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Social Media Platform
        </h1><br/>
        <div className="social-media-item">
          <i className="fab fa-twitter"></i>
          <a href="https://twitter.com/wiluminaty">TWITTER</a>
        </div>
        <div className="social-media-item">
          <i className="fab fa-whatsapp"></i>
          <a href="https://whatsapp.com/channel/0029VaA23l70LKZ5eWb9kw2C">
            WHATSAPP
          </a>
        </div>
        <div className="social-media-item">
          <i className="fab fa-facebook-f"></i>
          <a href="https://www.facebook.com/wiluminaty">FACEBOOK</a>
        </div>
        <div className="social-media-item">
          <i className="fab fa-instagram"></i>
          <a href="https://www.instagram.com/wiluminatylifestyle/">
            {" "}
            INSTAGRAM
          </a>
        </div>
        <div className="social-media-item">
          <i className="fab fa-linkedin"></i>
          <a href="https://www.linkedin.com/company/wiluminaty">LINKEDIN</a>
        </div>
        <div className="social-media-item">
          <i className="fab fa-telegram"></i>
          <a href="https://t.me/wiluminatytv">TELEGRAM</a>
        </div>
        <div className="social-media-item">
          <i className="fab fa-youtube"></i>
          <a href=" https://youtube.com/@wiluminatytv?si=bxowydNSqABLgnXU">
            YOUTUBE
          </a>
        </div>

        <div className="ecommerce">
        <h1
          style={{
            textAlign: "left",
            marginLeft: "1em",
            color: "white",
            fontWeight: "bold",
          }}
        >
          E-commerce Platform
        </h1>
        <br />
        <div className="ecommerce-item">
        {eComPlatformData.map((platform) => (
          <EComPlatformItem
            key={platform.platform}
            color={platform.color}
            borderRadius="10px"
          >
            {isImageUrl(platform.icon) ? (
              <CustomIcon src={platform.icon} alt={platform.platform} />
            ) : (
              <Icon className={`fab fa-${platform.icon}`}></Icon>
            )}
            <a
              href={platform.link}
              style={{ color: "white", textDecoration: "none" }}
            >
              {platform.platform.toUpperCase()}
            </a>
          </EComPlatformItem>
        ))}
        </div>
        </div>
        <h1
          style={{
           marginLeft:'27em',
            color: "white",
            fontWeight: "bold",
            marginTop:'-20em',
            position:'absolute'
          }}
        >
          Company Website
        </h1>
        <CompanyWebsiteItem color={companyWebsiteData.color}>
          <Icon className={`fas fa-${companyWebsiteData.icon}`}></Icon>
          <a
            href={companyWebsiteData.link}
            style={{ color: "white", textDecoration: "none" }}
          >
            {companyWebsiteData.platform.toUpperCase()}
          </a>
        </CompanyWebsiteItem>
              </div>
       
              
    </div>
    
  );
};

export default SocialMedia;
