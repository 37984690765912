import React from 'react';
import './ExchangePolicy.css'; // Assuming you have a CSS file for styling

const ExchangePolicy = () => {
    const backgroundImageStyle = {
      backgroundImage: 'url(/Images/orange1.jpg)',
       
      backgroundSize: 'cover',
      
      padding: '20px',
      
      };
    
  return (
    <div className='exchangepolicy' style={backgroundImageStyle}>
    <div className="exchange-policy-container" >
      <h1 style={{marginTop:'5em', textAlign:'center'}}>EXCHANGE, RETURNS OR CANCELLATION</h1>
      <p style={{ textAlign:'center'}}>This document explains the process and terms involved in Exchange, Return or Cancellation of orders</p>

      <h2>Validity</h2>
      <table>
        <thead>
          <tr>
            <th>Validity</th>
            <th>Covers</th>
            <th>Type Accepted</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2 days from delivery</td>
            <td>Exchange for size, Does not fit</td>
            <td>Exchange</td>
          </tr>
        </tbody>
      </table>

      <h2>How do I request an exchange?</h2>
      <p>
        If you are not satisfied with the fit of the product, you can exchange it within 2 days of delivery at no extra cost. 
        You can seek an exchange of a different size or color. Alternatively, you can return the product for WILUMINATY TV™ Wallet 
        Credits which can be used to place a fresh order. Please contact us with your exchange request within 2 days from the date 
        of delivery. The original item will be recalled and an exchange will be shipped to you.
      </p>
      <p>The product should be returned in its original condition at the time of delivery. Please ensure that</p>
      <ul>
        <li>Clothes are not used (other than trials), altered, washed, soiled or damaged.</li>
        <li>Original tags and accessories are intact. Branded packaging should be returned in an undamaged condition with the product.</li>
      </ul>

      <h2>Cancellations & Returns</h2>
      <h3>What is WILUMINATY TV’s Free & Easy Returns policy?</h3>
      <p>
        We love to get it right for you, every single time you trust us with your purchase. We want you to have an absolutely delightful 
        shopping experience. We assure you that all products sold on WILUMINATY TV are brand new and 100% genuine. In case the product 
        you have received is ‘Damaged’, ‘Defective’ or ‘Not as Described’, our Free & Easy Returns promise has got you covered.
      </p>
      <p>Possible resolution could be Replacement, Exchange or Refund, backed by the 2 Day Replacement Guarantee.</p>
      <p>
        In any case, all your purchases on WILUMINATY TV™ are backed by Buyer Protection for 45 days from the date of delivery, lest 
        you have any issues.
      </p>

      <h3>What is Free Replacement?</h3>
      <p>
        If you have received an item in a ‘Damaged’ or ‘Defective’ condition or it is ‘Not as Described’ by WILUMINATY TV™, you may 
        request a replacement at no extra cost. Returns are covered by the 2 Day Replacement Guarantee.
      </p>
      <p>
        Replacement is subject to availability of stock with WILUMINATY TV™. If the product is out of stock or discontinued, you will 
        receive a full refund, no questions asked.
      </p>

      <h3>What is Free Exchange?</h3>
      <p>
        If you are not satisfied with the Clothing item delivered, you can request an exchange in a different size, color or design 
        in the same category (Ladies, Gents and Kids). All this, again, at no extra cost to you. Returns are covered by the 2 Day 
        Replacement Guarantee.
      </p>
      <p>
        Exchange is subject to availability of stock with us. If the product is out of stock, you will receive a full refund, no 
        questions asked.
      </p>

      <h3>How do I return an item purchased on WILUMINATY TV™?</h3>
      <p>Returns are easy, simply follow these steps</p>
      <ul>
        <li>Contact us and provide Order ID details.</li>
        <li>We will confirm the return request and will inform you about the pickup process.</li>
        <li>
          Please ensure that product is in unused and original condition. Include all price tags, labels, original packing and invoice 
          along with the product.
        </li>
      </ul>
      <p>Note:</p>
      <ul>
        <li>We do not charge extra for returns and all replacements/pickups are done free of cost.</li>
        <li>We support Replacement in case of damaged item.</li>
        <li>In case of Refund, the entire amount paid by you including shipping and gift charges are transferred to your account</li>
        <li>
          We will inform you about the pickup process. While we pick up originally delivered item from most customer locations, there 
          are certain areas where a pickup cannot be arranged. In such a case, you can ship the product through any other courier. 
          You will be reimbursed the shipping charges against the original receipt.
        </li>
        <li>Replacement is subject to availability of stock. In case a Replacement is not available, we will refund the amount for the same.</li>
      </ul>

      <h3>What is the pickup process? Why I have been asked to ship the item?</h3>
      <p>
        Once you have requested for a replacement, we will schedule the pickup of the originally delivered product. Please ensure 
        that product is in unused and original condition. Include all price tags, labels, original packing and invoice along with 
        the product.
      </p>
      <ul>
        <li>We arrange for pickup from most of our customer locations. It’s simple. Please follow the instructions you received by email</li>
        <li>
          In case we can’t pick up the item from your location, you can ship it back to us. We will reimburse the shipping charges 
          against the original receipt
        </li>
        <li>Include all price tags, labels, original packing and invoice along with the product. Pack the item safely to ensure against damage in transit</li>
        <li>Mention the Product ID on the package so that we can acknowledge your return</li>
        <li>
          Do retain the original receipt for the courier charges you have paid, it will be required to reimburse the shipping charges. 
          Also note down the courier tracking id for any future reference
        </li>
        <li>Refund/replacement will be initiated as soon as we receive the product from you.</li>
      </ul>

      <h3>I have requested a replacement, when will I get it?</h3>
      <p>
        Once a replacement request is created, we send you an email detailing the pickup process as well as provide details about the 
        replacement.
      </p>
      <p>
        In most locations, the replacement item is delivered to you at the time of pick-up. In all other areas, the replacement is 
        initiated after the originally delivered item is picked up/received.
      </p>
      <p>If you don’t get the return within the promised date, contact us immediately.</p>

      <h3>Do I have to return the free gift when I return a product?</h3>
      <p>Yes. The free gift is included as part of the item order and needs to be returned along with the originally delivered product.</p>

      <h3>I have changed my mind and would like to retain the product. What do I do?</h3>
      <p>
        Please call us to cancel your return request and we will do the needful. In case the replacement is already dispatched, you 
        can reject the replacement item when it is delivered to you.
      </p>

      <h3>Can I return part of my order?</h3>
      <p>Yes, a return can be created at item level and if you have ordered multiple items, you can initiate a return for a partial quantity.</p>

      <h3>WILUMINATY TV™ has declined my request. What can I do?</h3>
      <p>
        In case WILUMINATY TV™ declines a replacement request and you are unconvinced with the reason provided, you can write to us 
        to at cs@WILUMINATY TV.com
      </p>
      <p>Before taking any step towards legal actions.</p>

      <h3>When are returns not possible?</h3>
      <p>There are certain scenarios where it is difficult for us to support returns</p>
      <ul>
        <li>Return request is made outside the specified time frame</li>
        <li>Product is damaged because of use or Product is not in the same condition as you received it</li>
        <li>Defective products which are covered under the manufacturer’s warranty</li>
        <li>Any consumable item which has been used</li>
        <li>Items that are returned without original packaging, freebies or accessories</li>
      </ul>

      <h3>Where can I find the WILUMINATY TV™ Return Policy?</h3>
      <p>Please go to the product page, where you can see the respective policy against each product.</p>

      <h3>What is WILUMINATY TV™’s Replacement Guarantee?</h3>
      <p>
        If you have received a product in a damaged or defective condition or it is not as described, you can return it to get a 
        replacement within 2 days of delivery.
      </p>
      <p>Please contact us with a replacement request. The item will be recalled and a brand new replacement will be shipped to you, at no extra cost.</p>

      <h3>When are Refunds provided?</h3>
      <p>We work to provide the most hassle-free online shopping experience. You are covered and your money safe.</p>
      <p>Refunds are issued when</p>
      <ul>
        <li>WILUMINATY TV™ cannot provide replacement</li>
        <li>In-line with Buyer Protection, when a dispute has been ruled in your favour</li>
      </ul>
      <p>Please check possibility of Return and Refund while purchasing any particular product.</p>

      <h3>I’ve still not received the refund to my bank account. Why?</h3>
      <p>
        If you have received a mail from us confirming your refund request then rest assured that we have initiated your refund request 
        and are following up with financial organizations for the same.
      </p>
      <p>
        Sometimes financial organizations take a longer time to process the refund request. However, if the refund hasn’t happened by 
        the date we promised, you can contact us. We will gladly help you.
      </p>

      <h3>How do I cancel an order?</h3>
      <p>You can cancel your order online before the product has been shipped. Your entire order amount will be refunded.</p>
      <p>
        In case the item you have ordered has been shipped but has not yet been delivered to you, you may still cancel the order online. 
        Your refund will be processed once we receive the originally ordered item back from the courier.
      </p>
      <p>Unfortunately, an order cannot be cancelled once the item has been delivered to you.</p>
      <p>In order to cancel an item in your order:</p>
      <ul>
        <li>Log into your WILUMINATY TV™ account and go to the ‘My Orders’ page</li>
        <li>Identify the item you want to cancel and click on the corresponding ‘View Details’ link</li>
        <li>In the detailed order page, you will see ‘Cancel’ links against each of the items in that order</li>
        <li>Click on the ‘Cancel’ link, indicate the reason for cancellation, choose a mode of refund and confirm cancellation</li>
        <li>Once your cancellation request is created, we will ensure that the cancellation is processed as soon as possible</li>
      </ul>

      <h3>Why do I see a disabled ‘Cancel’ link?</h3>
      <p>A disabled ‘Cancel’ link can mean the following things:</p>
      <ul>
        <li>The item is being shipped together with another order of yours</li>
        <li>The item is non-refundable (eg. e-Gift Vouchers)</li>
      </ul>
      <p>In any of the above cases, you can get in touch with us for more information</p>

      <h3>How long will it take to process my cancellation request?</h3>
      <p>
        Once you request the cancellation of item(s) in your order, it will take us a maximum of 1-2 business days to cancel the order 
        and initiate a refund. You will be notified of the same by email.
      </p>
      <p>
        If the Order Status shows the item(s) in your order as ‘Shipped’, we will process your cancellation request directly with our 
        logistics partner. Your refund will be processed soon after we receive the cancelled items back from the courier.
      </p>
      <p>
        However, if you opt for having the money transferred back to the source of transaction, it may take up to 7-10 business days 
        for the respective banks to process the refund. Please get in touch with the banks directly in case of any delays post 
        confirmation of cancellation/refund by WILUMINATY TV™.
      </p>
    </div>
    </div>
  );
};

export default ExchangePolicy;
