import React from 'react';
import './PromoCode.css';

const PromoCode = () => {
    const backgroundImageStyle = {
        backgroundImage: 'url(/Images/orange1.jpg)',
       
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '20px',
      
      };
  return (
    <div className="promo-code-container" style={backgroundImageStyle}>
      <h1 className='promocodeh1' >WILUMINATY TV PROMO CODE TERMS AND CONDITIONS</h1>
      <ul>
        <li>Cannot be applied to previously placed orders.</li>
        <li>Promo codes that offer a percent off can be used in combination with free shipping promo codes, but may not be combined with other percent off promo codes.</li>
        <li>Not transferable or redeemable for cash or credit.</li>
        <li>Certain products are excluded from promo codes. See the specific details of the promo code for a list of excluded products.</li>
        <li>To apply a promo code, you must enter it prior to completing the order.</li>
      </ul>
    </div>
  );
};

export default PromoCode;
