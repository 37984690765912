// src/components/Home.js
import React from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import './Home.css';
import LoginForm from './Login';

const BackgroundVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const Home = () => {
  return (
    <>
    <div className="home">
      <BackgroundVideo autoPlay loop muted>
        <source src="/Videos/background.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </BackgroundVideo>
      <div className="play-button">
        <img src="/Images/play-icon.png" alt="Play Icon" />
      </div>
      <br />
      <br />
      <LoginForm />
      <div className="live-channel" >
        <span className="fashiontv">Wiluminaty TV</span>
        &nbsp;&nbsp;&nbsp;
        
      </div>
      <div className="watch-now">WATCH NOW LIVE</div><br/><br/>
<div className='maincontent'>
      <section className="MAGAZINE">
        <h2 style={{color:'black'}}>MAGAZINE</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="NEWS AND ARTICLES">
        <h2 style={{color:'black'}}>NEWS AND ARTICLES</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="CHANNEL">
        <h2  style={{color:'black'}}>CHANNEL</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="MODEL">
        <h2 style={{color:'black'}}>MODEL</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
      <section className="PHOTOGRAPHER">
        <h2  style={{color:'black'}}>PHOTOGRAPHER</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="DESIGNER">
        <h2  style={{color:'black'}}>DESIGNER</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>


      <section className="story-of-models">
        <h2  style={{color:'black'}}>HAIR STYLIST</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="PRODUCT">
        <h2  style={{color:'black'}}>PRODUCT</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="MONTH'S TOPPER">
        <h2  style={{color:'black'}}>MONTH'S TOPPER</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>

      <section className="TOP10">
        <h2  style={{color:'black'}}>TOP 10</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        <section className="TOP100">
        <h2 style={{color:'black'}}>TOP 100</h2>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls autoPlay muted className="highlight">
          <source src="/videos/back.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </section>
      </section>
    </div>
    </div>
   </>
  );
};

export default Home;
