import React from "react";
import "./About.css";

const About = () => {
  const backgroundImageStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
       
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '20px',
      
  };

  return (
    <div style={backgroundImageStyle} className="about-page">
      <div className="about-header">
        <h2>About Us</h2>
        <p>
          Wiluminaty TV is a Fashion Channel which will become a largest digital
          resource in the world for the fashion sector. We have been collecting
          and distributing all the fashion shows, Fashion contents of the most
          famous fashion houses in the world and will deliver to our audience of
          around 600,000,000 people a year who will enjoy over 40,000 new videos
          every year online. These also include what we call Fashion Epic
          Moments, fashion shows that have marked the history of fashion at the
          hands of extraordinary designers and unrepeatable top models. That's
          how we decided to create a collaboration with WILUMINATY.IN lifestyle,
          the worldwide leading Company for Apparels, Footwear, Accessories and
          Wellness products, to fix the topical moments of the fashion industry.
        </p>
      </div>
      <div className="video-container">
        <video autoPlay loop muted>
          <source src="/videos/aboutus.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="right-side">
        <div className="video-container-right">
          <video autoPlay loop muted>
            <source src="/videos/about.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="additional-text">
          <h3>The WILUMINATY TV Vision:</h3>
          <p>
            • A Global Impact Illuminating India: A 1.5 billion Journey WILUMINATY
            TV sets its sights on India, a nation vibrant with diversity and
            culture. With a commitment to becoming a staple in the daily lives of
            1.5 billion Indians, the brand aims to weave its essence into the
            fashion of the nation. From the bustling streets of Delhi to the
            serene landscapes of Kerala, WILUMINATY TV envisions becoming
            synonymous with style and substance.
          </p>
          <p>
            • Beyond Borders: Touching Lives Across the Globe The mission doesn't
            stop at the borders of India. WILUMINATY TV is poised to make a mark
            on the remaining 1.5 billion individuals spread across the globe. From
            the bustling metropolises of New York to the serene villages of
            Africa, the brand aims to be a global phenomenon, influencing fashion
            choices and lifestyle preferences.
          </p>
          <p>
            • Crafting a WILUMINATY TV The Power of Fashion in Daily Life Fashion
            is not just about clothing; it's a statement, an expression of
            individuality. WILUMINATY TV understands this, and every product it
            offers is designed with the intent to empower individuals, allowing
            them to showcase their unique personalities through clothing,
            accessories, and more. Sustainable Living: WILUMINATY TV's Commitment
            In a world awakening to the importance of sustainability, WILUMINATY
            TV takes a bold stand. The brand is committed to producing fashion and
            lifestyle contents that not only elevate style but also embrace
            eco-friendly practices. From sourcing materials responsibly to
            minimizing the carbon footprint, WILUMINATY TV aims to lead by example
            in sustainable living.
          </p>
          <p>
            • The WILUMINATY TV Experience From Runways to Your Wardrobe
            WILUMINATY TV transcends the traditional boundaries of fashion by
            bringing the runway experience directly to consumers. The brand
            believes that everyone deserves to feel like a fashion icon, and thus,
            its crafts collections that blend high fashion with everyday wear.
            Elevate your daily style with WILUMINATY TV's signature touch. Digital
            Presence: Connecting in the Modern Age Living in the digital era,
            WILUMINATY TV recognizes the power of online presence. With a seamless
            digital experience, the brand aims to reach individuals in the
            farthest corners of the world. Through captivating content, engaging
            social media, and an intuitive online store, WILUMINATY invites you to
            be part of its global community.
          </p>
          <p>
            • Joining the WILUMINATY TV Movement Embrace the Change: Make
            WILUMINATY TV Your Lifestyle As WILUMINATY TV embarks on this
            monumental journey, it invites you to be part of the movement. Embrace
            the change, make WILUMINATY TV your lifestyle, and be a trendsetter in
            your own right. From Fashion enthusiasts to those seeking sustainable
            living, WILUMINATY caters to a diverse audience, Ensuring there’s
            something for everyone.
          </p>
         
      </div>
      <div className="conclusion">
          <h3>Conclusion: Your Fashionable Journey Begins</h3>
          <p>
            In conclusion, WILUMINATY TV’s mission isn’t confined to reaching a
            numerical milestone; it’s about Creating a global community united by
            style, sustainability, and individual expression. By seamlessly
            Integrating SEO strategies with a commitment to quality, exclusivity,
            and customer satisfaction, WILUMINATY TV paves the way for a future
            where fashion isn’t just a choice; it’s a statement. Embark on Your
            fashionable journey with WILUMINATY TV, where every search leads to a
            discovery, and every discovery Is a step towards a more stylish
            tomorrow.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
