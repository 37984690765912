import React from "react";
import "./termsanduse.css";

const TermsAndUse = () => {
  const containerStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
       
    backgroundSize: 'cover',
    
    padding: '20px',
  };

  return (
    <div className="termsofusebackground" style={containerStyle}>
    <div className="terms-container" >
      <h1>Terms of Use</h1>
      <p>
        <b>
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011 that require
          publishing the rules and regulations, privacy policy and Terms of Use
          for access or usage of www.wiluminaty.com website{" "}
        </b>
      </p>
      <p>
        The domain name www.wiluminaty.com (hereinafter referred to as
        “Website”) is owned by Abhinaye Kareshiya, (hereinafter referred to as
        “WILUMINATY”). Your use of the Website and services and tools are
        governed by the following terms and conditions (“Terms of Use”) as
        applicable to the Website including the applicable policies which are
        incorporated herein by way of reference. If you transact on the Website,
        You shall be subject to the policies that are applicable to the Website
        for such transaction. By mere use of the Website, You shall be
        contracting with WILUMINATY™ and these terms and conditions including
        the policies constitute your binding obligations, with WILUMINATY™.
      </p>
      <p>
        For the purpose of these Terms of Use, wherever the context so requires
        “You” or “User” shall mean any natural or legal person who has agreed to
        become a buyer on the Website by providing Registration Data while
        registering on the Website as Registered User using the computer
        systems. WILUMINATY™ allows the User to surf the Website or making
        purchases without registering on the Website. The term “We”, “Us”, “Our”
        shall mean WILUMINATY™.
      </p>
      <p>
        When You use any of the services provided by Us through the Website,
        including but not limited to, (e.g. Product Reviews, Seller Reviews),
        You will be subject to the rules, guidelines, policies, terms, and
        conditions applicable to such service, and they shall be deemed to be
        incorporated into this Terms of Use and shall be considered as part and
        parcel of this Terms of Use. We reserve the right, at our sole
        discretion, to change, modify, add or remove portions of these Terms of
        Use, at any time without any prior written notice to you. It is your
        responsibility to review these Terms of Use periodically for updates /
        changes. Your continued use of the Website following the posting of
        changes will mean that you accept and agree to the revisions. As long as
        you comply with these Terms of Use, We grant you a personal,
        non-exclusive, non-transferable, limited privilege to enter and use the
        Website.
      </p>
      <p>
        <b>
          ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
          AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO
          PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
        </b>{" "}
        By impliedly or expressly accepting these Terms of Use, You also accept
        and agree to be bound by WILUMINATY Policies ((including but not limited
        to Privacy Policy available on http://wiluminaty.com/privacy-policy/) as
        amended from time to time.
      </p>
      <h4>Membership Eligibility</h4>
      <p>
        Use of the Website is available only to persons who can form legally
        binding contracts under Indian Contract Act, 1872. Persons who are
        “incompetent to contract” within the meaning of the Indian Contract Act,
        1872 including minors, un-discharged insolvents etc. are not eligible to
        use the Website. If you are a minor i.e. under the age of 18 years, you
        shall not register as a User of the WILUMINATY™ website and shall not
        transact on or use the website. As a minor if you wish to use or
        transact on website, such use or transaction may be made by your legal
        guardian or parents on the Website. WILUMINATY™ reserves the right to
        terminate your membership and / or refuse to provide you with access to
        the Website if it is brought to WILUMINATY™’s notice or if it is
        discovered that you are under the age of 18 years.
      </p>
      <h4>Your Account and Registration Obligations</h4>
      <p>
        If you use the Website, You shall be responsible for maintaining the
        confidentiality of your Display Name and Password and you shall be
        responsible for all activities that occur under your Display Name and
        Password. You agree that if You provide any information that is untrue,
        inaccurate, not current or incomplete or We have reasonable grounds to
        suspect that such information is untrue, inaccurate, not current or
        incomplete, or not in accordance with the this Terms of Use, We shall
        have the right to indefinitely suspend or terminate or block access of
        your membership on the Website and refuse to provide You with access to
        the Website.
      </p>
      <h4>Communications</h4>
      <p>
        When You use the Website or send emails or other data, information or
        communication to us, You agree and understand that You are communicating
        with Us through electronic records and You consent to receive
        communications via electronic records from Us periodically and as and
        when required. We may communicate with you by email or by such other
        mode of communication, electronic or otherwise.
      </p>
      <h4>Platform for Transaction and Communication</h4>
      <p>
        The Website is a platform that Users utilize to meet and interact with
        one another for their transactions. WILUMINATY™ is not and cannot be a
        party to or control in any manner any transaction between the Website’s
        Users.
      </p>
      <p>
        Disclaimer: Pricing on any product(s) as is reflected on the Website may
        due to some technical issue, typographical error or product information
        published may be incorrectly reflected and in such an event we may
        cancel such your order(s). You release and indemnify WILUMINATY™ and/or
        any of its officers and representatives from any cost, damage, liability
        or other consequence of any of the actions of the Users of the Website
        and specifically waive any claims that you may have in this behalf under
        any applicable law. Notwithstanding its reasonable efforts in that
        behalf, WILUMINATY™ cannot take responsibility or control the
        information provided by other Users which is made available on the
        Website. You may find other User’s information to be offensive, harmful,
        inconsistent, inaccurate, or deceptive. Please use caution and practice
        safe trading when using the Website. Please note that there could be
        risks in dealing with underage persons or people acting under false
        pretence.
      </p>
      <h4>Charges</h4>
      <p>
        Membership on the Website is free for buyers. WILUMINATY™ does not
        charge any fee for browsing and buying on the Website. WILUMINATY™
        reserves the right to change its Fee Policy from time to time. In
        particular, WILUMINATY™ may at its sole discretion introduce new
        services and modify some or all of the existing services offered on the
        Website. In such an event WILUMINATY™ reserves the right to introduce
        fees for the new services offered or amend/introduce fees for existing
        services, as the case may be. Changes to the Fee Policy shall be posted
        on the Website and such changes shall automatically become effective
        immediately after they are posted on the Website. Unless otherwise
        stated, all fees shall be quoted in Indian Rupees. You shall be solely
        responsible for compliance of all applicable laws including those in
        India for making payments to WILUMINATY™.
      </p>
      <h4>Use of the Website</h4>
      <p>
        You agree, undertake and confirm that Your use of Website shall be
        strictly governed by the following binding principles: 1. You shall not
        host, display, upload, modify, publish, transmit, update or share any
        information which:
      </p>
      <p>
        (a) belongs to another person and to which You does not have any right
        to;
        <p>
          (b) is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, pedophilic, libelous, invasive of another’s privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever; or unlawfully threatening or unlawfully harassing
          including but not limited to “indecent representation of women” within
          the meaning of the Indecent Representation of Women (Prohibition) Act,
          1986;
        </p>
        <p> (c) is misleading in any way; </p>
        <p>
          (d) is patently offensive to the online community, such as sexually
          explicit content, or content that promotes obscenity, pedophilia,
          racism, bigotry, hatred or physical harm of any kind against any group
          or individual;
        </p>{" "}
        <p>(e) Harasses or advocates harassment of another person;</p>
        <p>
          {" "}
          (f) Involves the transmission of “junk mail”, “chain letters”, or
          unsolicited mass mailing or “spamming”;
        </p>{" "}
        <p>
          (g) promotes illegal activities or conduct that is abusive,
          threatening, obscene, defamatory or libelous;
        </p>{" "}
        <p>
          (h) infringes upon or violates any third party’s rights [including,
          but not limited to, intellectual property rights, rights of privacy
          (including without limitation unauthorized disclosure of a person's
          name, email address, physical address or phone number) or rights of
          publicity];
        </p>
        <p>
          {" "}
          (i) promotes an illegal or unauthorized copy of another person’s
          copyrighted work (see “Copyright complaint” below for instructions on
          how to lodge a complaint about uploaded copyrighted material), such as
          providing pirated computer programs or links to them, providing
          information to circumvent manufacture-installed copy-protect devices,
          or providing pirated music or links to pirated music files;{" "}
        </p>
        <p>
          (j) Contains restricted or password-only access pages, or hidden pages
          or images (those not linked to or from another accessible page);{" "}
        </p>
        <p>
          (k) Provides material that exploits people in a sexual, violent or
          otherwise inappropriate manner or solicits personal information from
          anyone;
        </p>
        <p>
          {" "}
          (l) Provides instructional information about illegal activities such
          as making or buying illegal weapons, violating someone’s privacy, or
          providing or creating computer viruses;
        </p>
        <p>
          {" "}
          (m) contains video, photographs, or images of another person (with a
          minor or an adult).{" "}
        </p>
        <p>
          (n) tries to gain unauthorized access or exceeds the scope of
          authorized access to the Website or to profiles, blogs, communities,
          account information, bulletins, friend request, or other areas of the
          Website or solicits passwords or personal identifying information for
          commercial or unlawful purposes from other users;{" "}
        </p>
        <p>
          (o) Engages in commercial activities and/or sales without our prior
          written consent such as contests, sweepstakes, barter, advertising and
          pyramid schemes, or the buying or selling of “virtual” products
          related to the Website. Throughout this Terms of Use, WILUMINATY’s
          prior written consent means a communication coming from WILUMINATY’s
          Legal Department, specifically in response to your request, and
          specifically addressing the activity or conduct for which you seek
          authorization;{" "}
        </p>
        <p>
          (p) Solicits gambling or engages in any gambling activity which we, in
          our sole discretion, believes is or could be construed as being
          illegal;
        </p>{" "}
        <p>
          (q) Interferes with another USER’s use and enjoyment of the Website or
          any other individual’s User and enjoyment of similar services;
        </p>
        <p>
          {" "}
          (r) Refers to any website or URL that, in our sole discretion,
          contains material that is inappropriate for the Website or any other
          website, contains content that would be prohibited or violates the
          letter or spirit of these Terms of Use.{" "}
        </p>
        <p>(s) Harm minors in any way; </p>
        <p>
          (t) infringes any patent, trademark, copyright or other proprietary
          rights or third party’s trade secrets or rights of publicity or
          privacy or shall not be fraudulent or involve the sale of counterfeit
          or stolen products;
        </p>
        <p> (u) violates any law for the time being in force; </p>
        <p>
          (v) Deceives or misleads the addressee/ users about the origin of such
          messages or communicates any information which is grossly offensive or
          menacing in nature;{" "}
        </p>
        <p>(w) Impersonate another person;</p>
        <p>
          (x) contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource; or contains any Trojan horses, worms, time
          bombs, Cancel bots, Easter eggs or other computer programming routines
          that may damage, detrimentally interfere with, diminish value of,
          surreptitiously intercept or expropriate any system, data or personal
          information;
        </p>
        <p>
          {" "}
          (y) Threatens the unity, integrity, defense, security or sovereignty
          of India, friendly relations with foreign states, or public order or
          causes incitement to the commission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation.{" "}
        </p>
        <p>(z) Shall not be false, inaccurate or misleading;</p>
        <p>
          {" "}
          (aa) shall not, directly or indirectly, offer, attempt to offer, trade
          or attempt to trade in any item, the dealing of which is prohibited or
          restricted in any manner under the provisions of any applicable law,
          rule, regulation or guideline for the time being in force.
        </p>{" "}
        <p>
          (ab) shall not create liability for Us or cause Us to lose (in whole
          or in part) the services of Our internet service provider (“ISPs”) or
          other suppliers;
        </p>{" "}
        <p>
          2. You shall not use any “deep-link”, “page-scrape”, “robot”, “spider”
          or other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to access, acquire, copy or
          monitor any portion of the Website or any Content, or in any way
          reproduce or circumvent the navigational structure or presentation of
          the Website or any Content, to obtain or attempt to obtain any
          materials, documents or information through any means not purposely
          made available through the Website. We reserve our right to bar any
          such activity.
        </p>{" "}
        <p>
          3. You shall not attempt to gain unauthorized access to any portion or
          feature of the Website, or any other systems or networks connected to
          the Website or to any server, computer, network, or to any of the
          services offered on or through the Website, by hacking, password
          “mining” or any other illegitimate means.
        </p>
        <p>
          {" "}
          4. You shall not probe, scan or test the vulnerability of the Website
          or any network connected to the Website nor breach the security or
          authentication measures on the Website or any network connected to the
          Website. You may not reverse look-up, trace or seek to trace any
          information on any other User of or visitor to Website, or any other
          customer, including any account on the Website not owned by You, to
          its source, or exploit the Website or any service or information made
          available or offered by or through the Website, in any way where the
          purpose is to reveal any information, including but not limited to
          personal identification or information, other than Your own
          information, as provided for by the Website.
        </p>
        <p>
          {" "}
          5. You shall not make any negative, denigrating or defamatory
          statement(s) or comment(s) about Us or the brand name or domain name
          used by Us including the terms WILUMINATY™, wiluminaty.com, or
          otherwise engage in any conduct or action that might tarnish the image
          or reputation, of WILUMINATY™ or otherwise tarnish or dilute any
          WILUMINATY’s trade or service marks, trade name and/or goodwill
          associated with such trade or service marks, trade name as may be
          owned or used by us. You agree that you will not take any action that
          imposes an unreasonable or disproportionately large load on the
          infrastructure of the Website or WILUMINATY’s systems or networks, or
          any systems or networks connected to WILUMINATY™.{" "}
        </p>
        <p>
          6. You agree not to use any device, software or routine to interfere
          or attempt to interfere with the proper working of the Website or any
          transaction being conducted on the Website, or with any other person’s
          use of the Website.
        </p>{" "}
        <p>
          {" "}
          7. You may not forge headers or otherwise manipulate identifiers in
          order to disguise the origin of any message or transmittal you send to
          us on or through the Website or any service offered on or through the
          Website. You may not pretend that you are, or that You represent,
          someone else, or impersonate any other individual or entity.
        </p>
        <p>
          {" "}
          8. You may not use the Website or any content for any purpose that is
          unlawful or prohibited by these Terms of Use, or to solicit the
          performance of any illegal activity or other activity which infringes
          the rights of WILUMINATY™.
        </p>
        <p>
          {" "}
          9. You shall at all times ensure full compliance with the applicable
          provisions of the Information Technology Act, 2000 and rules there
          under as applicable and as amended from time to time and also all
          applicable Domestic laws, rules and regulations (including the
          provisions of any applicable Exchange Control Laws or Regulations in
          Force) and International Laws, Foreign Exchange Laws, Statutes,
          Ordinances and Regulations (including, but not limited to Sales
          Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty,
          Local Levies) regarding Your use of Our service and Your listing,
          purchase, solicitation of offers to purchase, and sale of products or
          services. You shall not engage in any transaction in an item or
          service, which is prohibited by the provisions of any applicable law
          including exchange control laws or regulations for the time being in
          force.
        </p>
        <p>
          {" "}
          10. Solely to enable Us to use the information You supply Us with, so
          that we are not violating any rights You might have in Your
          Information, You agree to grant Us a non-exclusive, worldwide,
          perpetual, irrevocable, royalty-free, sub-licensable (through multiple
          tiers) right to exercise the copyright, publicity, database rights or
          any other rights You have in Your Information, in any media now known
          or not currently known, with respect to Your Information. We will only
          use your information in accordance with the Terms of Use and Privacy
          Policy applicable to use of the Website.{" "}
        </p>
        <p>
          11. You shall not engage in advertising to, or solicitation of, other
          Users of the Website to buy or sell any products or services,
          including, but not limited to, products or services related to that
          being displayed on the Website or related to us. You may not transmit
          any chain letters or unsolicited commercial or junk email to other
          Users via the Website. It shall be a violation of these Terms of Use
          to use any information obtained from the Website in order to harass,
          abuse, or harm another person, or in order to contact, advertise to,
          solicit, or sell to another person other than Us without Our prior
          explicit consent. In order to protect Our Users from such advertising
          or solicitation, We reserve the right to restrict the number of
          messages or emails which a user may send to other Users in any 24-hour
          period which We deems appropriate in its sole discretion. You
          understand that we have the right at all times to disclose any
          information (including the identity of the persons providing
          information or materials on the Website) as necessary to satisfy any
          law, regulation or valid governmental request. This may include,
          without limitation, disclosure of the information in connection with
          investigation of alleged illegal activity or solicitation of illegal
          activity or in response to a lawful court order or subpoena. In
          addition, We can (and You hereby expressly authorize Us to) disclose
          any information about You to law enforcement or other government
          officials, as we, in Our sole discretion, believe necessary or
          appropriate in connection with the investigation and/or resolution of
          possible crimes, especially those that may involve personal injury. We
          reserve the right, but has no obligation, to monitor the materials
          posted on the Website. WILUMINATY™ shall have the right to remove or
          edit any content that in its sole discretion violates, or is alleged
          to violate, any applicable law or either the spirit or letter of these
          Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY
          RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE
          AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content
          posted does not necessarily reflect WILUMINATY™ views. In no event
          shall WILUMINATY™ assume or have any responsibility or liability for
          any Content posted or for any claims, damages or losses resulting from
          use of Content and/or appearance of Content on the Website. You hereby
          represent and warrant that you have all necessary rights in and to all
          Content which You provide and all information it contains and that
          such Content shall not infringe any proprietary or other rights of
          third parties or contain any libelous, tortuous, or otherwise unlawful
          information.{" "}
        </p>
        <p>
          12. It is possible that other user (including unauthorized users or
          “hackers”) may post or transmit offensive or obscene materials on the
          Website and that you may be involuntarily exposed to such offensive
          and obscene materials. It also is possible for others to obtain
          personal information about you due to your use of the Website, and
          that the recipient may use such information to harass or injure you.
          We does not approve of such unauthorized uses, but by using the
          Website You acknowledge and agree that We are not responsible for the
          use of any personal information that You publicly disclose or share
          with others on the Website. Please carefully select the type of
          information that you publicly disclose or share with others on the
          Website.
        </p>{" "}
        <p>
          13. WILUMINATY™ shall have all the rights to take necessary action and
          claim damages that may occur due to your involvement/participation in
          any way on your own or through group/s of people, intentionally or
          unintentionally in DoS/DDoS (Distributed Denial of Services). Contents
          Posted on Site Except as expressly provided in these Terms of Use, no
          part of the Website and no Content may be copied, reproduced,
          republished, uploaded, posted, publicly displayed, encoded,
          translated, transmitted or distributed in any way (including
          “mirroring”) to any other computer, server, Website or other medium
          for publication or distribution or for any commercial enterprise,
          without WILUMINATY’s express prior written consent. You may use
          information on the products and services purposely made available on
          the Website for downloading, provided that You (1) do not remove any
          proprietary notice language in all copies of such documents, (2) use
          such information only for your personal, non-commercial informational
          purpose and do not copy or post such information on any networked
          computer or broadcast it in any media, (3) make no modifications to
          any such information, and (4) do not make any additional
          representations or warranties relating to such documents.
        </p>
        <p>
          {" "}
          You shall be responsible for any notes, messages, emails, billboard
          postings, photos, drawings, profiles, opinions, ideas, images, videos,
          audio files or other materials or information posted or transmitted to
          the Website (collectively, “Content”). Such Content will become our
          property and You grant Us the worldwide, perpetual and transferable
          rights in such Content. We shall be entitled to, consistent with Our
          Privacy Policy as adopted in accordance with applicable law, use the
          Content or any of its elements for any type of use forever, including
          but not limited to promotional and advertising purposes and in any
          media whether now known or hereafter devised, including the creation
          of derivative works that may include the Content You provide. You
          agree that any Content You post may be used by us, consistent with Our
          Privacy Policy and Rules of Conduct on Site as mentioned herein, and
          you are not entitled to any payment or other compensation for such
          use.
        </p>
      </p>
      <h4>Contents Posted On Sites</h4>
      <p>
        Except as expressly provided in these Terms of Use, no part of the
        Website and no Content may be copied, reproduced, republished, uploaded,
        posted, publicly displayed, encoded, translated, transmitted or
        distributed in any way (including “mirroring”) to any other computer,
        server, Website or other medium for publication or distribution or for
        any commercial enterprise, without WILUMINATY’s express prior written
        consent
      </p>
      <p>
        You may use information on the products and services purposely made
        available on the Website for downloading, provided that You (1) do not
        remove any proprietary notice language in all copies of such documents,
        (2) use such information only for your personal, non-commercial
        informational purpose and do not copy or post such information on any
        networked computer or broadcast it in any media, (3) make no
        modifications to any such information, and (4) do not make any
        additional representations or warranties relating to such documents.
      </p>
      <p>
        You shall be responsible for any notes, messages, emails, billboard
        postings, photos, drawings, profiles, opinions, ideas, images, videos,
        audio files or other materials or information posted or transmitted to
        the Website (collectively, “Content”). Such Content will become our
        property and You grant Us the worldwide, perpetual and transferable
        rights in such Content. We shall be entitled to, consistent with Our
        Privacy Policy as adopted in accordance with applicable law, use the
        Content or any of its elements for any type of use forever, including
        but not limited to promotional and advertising purposes and in any media
        whether now known or hereafter devised, including the creation of
        derivative works that may include the Content You provide. You agree
        that any Content You post may be used by us, consistent with Our Privacy
        Policy and Rules of Conduct on Site as mentioned herein, and you are not
        entitled to any payment or other compensation for such use.
      </p>
      <h4> Privacy</h4>
      <p>
        We view protection of your privacy as a very important principle. We
        understand clearly that You and Your Personal Information is one of our
        most important assets. We store and process Your Information including
        any sensitive financial information collected (as defined under the
        Information Technology Act, 2000), if any, on computers that may be
        protected by physical as well as reasonable technological security
        measures and procedures in accordance with Information Technology Act
        2000 and Rules there under. Our current Privacy Policy is available at
        http://WILUMINATY.com/privacy-policy. If you object to Your Information
        being transferred or used in this way please do not use Website. We and
        our affiliates will share / sell / transfer / license / covey some or
        all of your personal information with another business entity should we
        (or our assets) plan to merge with or are acquired by that business
        entity, or re-organization, amalgamation, restructuring of business or
        for any other reason whatsoever. Should such a transaction or situation
        occur, the other business entity or the new combined entity will be
        required to follow the privacy policy with respect to your personal
        information? Once you provide your information to us, you provide such
        information to us and our affiliate and we and our affiliate may use
        such information to provide you various services with respect to your
        transaction whether such transaction are conducted on www.WILUMINATY.com
        or with third party merchant’s or third party merchant’s website.{" "}
      </p>
      <h4>Disclaimer of Warranties and Liability</h4>
      <p>
        This Website, all the materials and products (including but not limited
        to software) and services, included on or otherwise made available to
        you through this site are provided on “as is” and “as available” basis
        without any representation or warranties, express or implied except
        otherwise specified in writing. Without prejudice to the forgoing
        paragraph, WILUMINATY™ does not warrant that:
        <p>
          {" "}
          • This Website will be constantly available, or available at all; or{" "}
        </p>
        <p>
          • The information on this Website is complete, true, accurate or
          non-misleading.
        </p>{" "}
        WILUMINATY™ will not be liable to you in any way or in relation to the
        Contents of, or use of, or otherwise in connection with, the Website.
        WILUMINATY™ does not warrant that this site; information, Content,
        materials, product (including software) or services included on or
        otherwise made available to You through the Website; their servers; or
        electronic communication sent from Us are free of viruses or other
        harmful components. You will be required to enter a valid phone number
        while placing an order on the Website. By registering your phone number
        with us, you consent to be contacted by us via phone calls and/or SMS
        notifications, in case of any order or shipment or delivery related
        updates. We will not use your personal information to initiate any
        promotional phone calls or SMS.
      </p>
      <h4>Services </h4>
      <h4> Payment</h4>
      <p>
        {" "}
        While availing any of the payment method/s available on the Website, we
        will not be responsible or assume any liability, whatsoever in respect
        of any loss or damage arising directly or indirectly to you due to:
      </p>
      <p> 1. Lack of authorization for any transaction/s, or </p>
      <p>
        2. Exceeding the preset limit mutually agreed by You and between
        “Bank/s”, or{" "}
      </p>
      <p>3. Any payment issues arising out of the transaction, or </p>
      <p>
        4. Decline of transaction for any other reason/s All payments made
        against the purchases/services on Website by you shall be compulsorily
        in Indian Rupees acceptable in the Republic of India. Website will not
        facilitate transaction with respect to any other form of currency with
        respect to the purchases made on Website.
      </p>
      <p>
        {" "}
        Before shipping / delivering your order to you, WILUMINATY may request
        you to provide supporting documents (including but not limited to Govt.
        issued ID and address proof) to establish the ownership of the payment
        instrument used by you for your purchase. This is done in the interest
        of providing a safe online shopping environment to Our Users.
      </p>
      <p> Further:</p>
      <p>
        1. You have specifically authorized WILUMINATY to collect, process,
        facilitate and remit payments and / or the Transaction Price
        electronically or through Cash on Delivery to and from other Users in
        respect of transactions through Payment Facility.{" "}
      </p>
      <p>
        2. You understand, accept and agree that the payment facility provided
        by WILUMINATY is neither a banking nor financial service but is merely a
        facilitator providing an electronic, automated online electronic
        payment, receiving payment through Cash On Delivery, collection and
        remittance facility for the Transactions on the WILUMINATY Website using
        the existing authorized banking infrastructure and Credit Card payment
        gateway networks. Further, by providing Payment Facility, WILUMINATY is
        neither acting as trustees nor acting in a fiduciary capacity with
        respect to the Transaction or the Transaction Price.
      </p>
      <h4> Payment Facility for Buyers:</h4>
      <p>
        •You, as a Buyer, understand that upon initiating a Transaction You are
        entering into a legally binding and enforceable contract with the Seller
        to purchase the products and /or services from the WILUMINATY™ using the
        Payment Facility, and you shall pay the Transaction Price through Your
        Issuing Bank to the Seller using Payment Facility.
      </p>
      <p>
        {" "}
        • You, as a Buyer, may agree with WILUMINATY™ through electronic
        communication and electronic records and using the automated features as
        may be provided by Payment Facility on any extension / increase in the
        Dispatch and/or Delivery time and the Transaction shall stand amended to
        such extent. Any such extension / increase of Dispatch / Delivery time
        or subsequent notation / variation of the Transaction should be in
        compliance with Payment Facility Rules and Policies.
      </p>
      <p>
        • You, as a Buyer, shall electronically notify Payment Facility using
        the appropriate WILUMINATY Website features immediately upon Delivery or
        non Delivery within the time period as provided in Policies. Non
        notification by You of Delivery or non Delivery within the time period
        specified in the Policies shall be construed as a deemed Delivery in
        respect of that Transaction. In case of Cash on Delivery transactions,
        Buyer is not required to confirm the receipt of products or services.{" "}
      </p>
      <p>
        • You, as a Buyer, shall be entitled to claim a refund of the
        Transaction Price (as Your sole and exclusive remedy) in case You do not
        receive the Delivery within the time period agreed in the Transaction or
        within the time period as provided in the Policies, whichever is
        earlier. In case you do not raise a refund claim using Website features
        within the stipulated time than this would make you ineligible for a
        refund.{" "}
      </p>
      <p>
        • You, as a Buyer, understand that the Payment Facility may not be
        available in full or in part for certain category of products and/or
        services and/or Transactions as mentioned in the Policies and hence you
        may not be entitled to a refund in respect of the Transactions for those
        products and /or services
      </p>
      <p>
        • Except for Cash On Delivery transaction, refund, if any, shall be made
        at the same Issuing Bank from where Transaction Price was received.{" "}
      </p>
      <p>
        • For Cash On Delivery transactions, refunds, if any, will be made via
        demand draft in favor of the Buyer (As per registration details provided
        by the Buyer){" "}
      </p>
      <p>
        • Refund shall be made in Indian Rupees only and shall be equivalent to
        the Transaction Price received in Indian Rupees.
      </p>
      <p>
        {" "}
        • For electronics payments, refund shall be made through payment
        facility using NEFT / RTGS or any other online banking / electronic
        funds transfer system approved by Reserve Bank India (RBI).{" "}
      </p>
      <p>
        • Refund shall be conditional and shall be with recourse available to
        WILUMINATY in case of any misuse by Buyer.
      </p>
      <p> • Refund shall be subject to Buyer complying with Policies.</p>
      <p>
        {" "}
        4. WILUMINATY™ reserves the right to impose limits on the number of
        Transactions or Transaction Price which WILUMINATY™ may receive from on
        an individual Valid Credit/Debit/ Cash Card / Valid Bank Account/ and
        such other infrastructure or any other financial instrument directly or
        indirectly through payment aggregator or through any such facility
        authorized by Reserve Bank of India to provide enabling support facility
        for collection and remittance of payment or by an individual Buyer
        during any time period, and reserves the right to refuse to process
        Transactions exceeding such limit.
      </p>
      <p>
        {" "}
        5. WILUMINATY™ reserves the right to refuse to process Transactions by
        Buyers with a prior history of questionable charges including without
        limitation breach of any agreements by Buyer with WILUMINATY™ or
        breach/violation of any law or any charges imposed by Issuing Bank or
        breach of any policy.{" "}
      </p>
      <p>
        6. WILUMINATY™ may do such checks as it deems fit before approving the
        receipt of/Buyers commitment to pay (for Cash On Delivery transactions)
        Transaction Price from the Buyer for security or other reasons at the
        discretion of WILUMINATY™. As a result of such check if WILUMINATY™ is
        not satisfied with the creditability of the Buyer or genuineness of the
        Transaction / Transaction Price, it will have the right to reject the
        receipt of / Buyers commitment to pay Transaction Price.{" "}
      </p>{" "}
      <p>
        7. WILUMINATY™ may delay notifying the payment confirmation i.e.
        Dispatching, if WILUMINATY™ deems suspicious or for Buyers conducting
        high transaction volumes to ensure safety of the Transaction and
        Transaction Price. In addition, WILUMINATY™ may hold Transaction Price
        and WILUMINATY™ may not Dispatch or remit Transaction Price to law
        enforcement officials (instead of refunding the same to Buyer) at the
        request of law enforcement officials or in the event the Buyer is
        engaged in any form of illegal activity.
      </p>{" "}
      <p>
        {" "}
        8. The Buyer acknowledge that WILUMINATY™ will not be liable for any
        damages, interests or claims etc. resulting from not processing a
        Transaction/Transaction Price or any delay in processing a
        Transaction/Transaction Price which is beyond control of WILUMINATY™.
      </p>
      <h4>Compliance with Laws:</h4>
      <p>
        {" "}
        9. Buyer shall comply with all the applicable laws (including without
        limitation Foreign Exchange Management Act, 1999 and the rules made and
        notifications issued there under and the Exchange Control Manual as may
        be issued by Reserve Bank of India from time to time, Customs Act,
        Information and Technology Act, 2000 as amended by the Information
        Technology (Amendment) Act 2008, Prevention of Money Laundering Act,
        2002 and the rules made there under, Foreign Contribution Regulation
        Act, 1976 and the rules made there under, Income Tax Act, 1961 and the
        rules made there under, Export Import Policy of government of India)
        applicable to them respectively for using Payment Facility and
        WILUMINATY™ Website.
      </p>
      <h4>Buyer’s arrangement with Issuing Bank:</h4>
      <p>
        10. All Valid Credit / Debit/ Cash Card/ and other payment instruments
        are processed using a Credit Card payment gateway or appropriate payment
        system infrastructure and the same will also be governed by the terms
        and conditions agreed to between the Buyer and the respective Issuing
        Bank and payment instrument issuing company.
      </p>{" "}
      <p>
        {" "}
        11. All Online Bank Transfers from Valid Bank Accounts are processed
        using the gateway provided by the respective Issuing Bank which support
        Payment Facility to provide these services to the Users. All such Online
        Bank Transfers on Payment Facility are also governed by the terms and
        conditions agreed to between Buyer and the respective Issuing Bank.
      </p>
      <h4> 30 Day Replacement Guarantee*</h4>
      <p>
        {" "}
        The 30 Day Replacement Guarantee seeks to assist Buyers who have been
        defrauded by qualified sellers on the Website. If at the time of
        delivery and/or within 30 days from the date of delivery of the
        product/s, if any defect is found, then the buyer of the product/s can
        ask for replacement of the product/s from the seller subject to the
        following terms and conditions:
      </p>
      <p>
        {" "}
        1. Notify WILUMINATY™ of any defects in the product/s at the time of
        delivery of the product/s and/or within 15 days from the date of
        delivery and the same product/s will be replaced in return of the
        defective product/s.{" "}
      </p>{" "}
      <p>
        2. Replacement can be for the entire product/s or part/s of the product
        subject to availability of the same with WILUMINATY™. Following products
        shall not be eligible for return or replacement:
      </p>
      <p> • Damages due to misuse of product;</p>
      <p> • Incidental damage; </p>
      <p>
        • Any garment which has been used; • Products with tampered or missing
        serial/UPC numbers;{" "}
      </p>
      <p>
        • Any damage/defect which are not covered under the manufacturer’s
        warranty{" "}
      </p>
      <p>
        • Any product that is returned without all original packaging and
        accessories, including the box, manufacturer’s packaging if any, and all
        other items originally included with the product/s delivered; On
        Clothing WILUMINATY™ accepts 30 day exchange subject to the following
        conditions: Clothes are not used (other than for trial), altered,
        washed, soiled or damaged in any way. Original tags and packaging should
        be intact. For items that come in branded packaging, the box should be
        undamaged. Damaged or defective clothing products are meanwhile covered
        by the 30 Day Replacement Guarantee.
      </p>
      <p>
        {" "}
        If WILUMINATY™ has suspicion or knowledge, that any of its buyers are
        involved in any activity that is intended to provide claims or
        information that is false, misleading or not genuine, then WILUMINATY™
        may while reserving its rights to initiate civil and/or criminal
        proceedings against member may also at its sole discretion suspend,
        block, restrict, cancel the Display Name of such buyer /or disqualify
        that user and any related users from availing protection through this
        program.
      </p>
      <p>
        {" "}
        WILUMINATY™ reserves its right to initiate civil and/or criminal
        proceedings against a user who, files a invalid and/or false claims or
        provides false, incomplete, or misleading information. In addition to
        the legal proceedings as aforesaid, WILUMINATY™ may at its sole
        discretion suspend, block, restrict, cancel the Display Name [and its
        related Display Names] of such user and/or disqualify that user and any
        related users from availing protection through this program. Any person
        who, knowingly and with intent to injure, defrauds or deceives, files a
        Fraudulent Complaint containing false, incomplete, or misleading
        information may be guilty of a criminal offence and will be prosecuted
        to the fullest extent of the law.
      </p>
      <h4>Indemnity</h4>
      <p>
        {" "}
        You shall indemnify and hold harmless WILUMINATY™, its owner, licensee,
        affiliates, subsidiaries, group companies (as applicable) and their
        respective officers, directors, agents, and employees, from any claim or
        demand, or actions including reasonable attorneys’ fees, made by any
        third party or penalty imposed due to or arising out of Your breach of
        this Terms of Use, privacy Policy and other Policies, or Your violation
        of any law, rules or regulations or the rights (including infringement
        of intellectual property rights) of a third party.
      </p>
      <h4> Applicable Law</h4>
      <p>
        {" "}
        Terms of Use shall be governed by and interpreted and construed in
        accordance with the laws of India. The place of jurisdiction shall be
        exclusively in Nagpur.
      </p>
      <h4> Jurisdictional Issues/Sale in India Only</h4>
      <p>
        {" "}
        Unless otherwise specified, the material on the Website is presented
        solely for the purpose of sale in India. WILUMINATY™ makes no
        representation that materials in the Website are appropriate or
        available for use in other locations/Countries other than India. Those
        who choose to access this site from other locations/Countries other than
        India do so on their own initiative and WILUMINATY is not responsible
        for supply of products/refund for the products ordered from other
        locations/Countries other than India, compliance with local laws, if and
        to the extent local laws are applicable.
      </p>
      <h4> Trademark, Copyright and Restriction</h4>
      <p>
        {" "}
        This site is controlled and operated by WILUMINATY™ and products are
        sold WILUMINATY™ only. All material on this site, including images,
        illustrations, audio clips, and video clips, are protected by
        copyrights, trademarks, and other intellectual property rights. Material
        on Website is solely for your personal, non-commercial use. You must not
        copy, reproduce, republish, upload, post, transmit or distribute such
        material in any way, including by email or other electronic means and
        whether directly or indirectly and you must not assist any other person
        to do so. Without the prior written consent of the owner, modification
        of the materials, use of the materials on any other website or networked
        computer environment or use of the materials for any purpose other than
        personal, non-commercial use is a violation of the copyrights,
        trademarks and other proprietary rights, and is prohibited. Any use for
        which you receive any remuneration, whether in money or otherwise, is a
        commercial use for the purposes of this clause.
      </p>
      <h4>Trademark complaint</h4>
      <p>
        {" "}
        WILUMINATY™ respects the intellectual property of others. In case You
        feel that Your Trademark has been infringed, You can write to
        WILUMINATY™ at info@wiluminaty.com.
      </p>
      <h4> Product Description</h4>
      <p>
        {" "}
        WILUMINATY we do not warrant that Product description or other content
        of this Website is accurate, complete, reliable, current, or error-free
        and assumes no liability in this regard.
      </p>
      <h4> Limitation of Liability</h4>
      <p>
        IN NO EVENT SHALL WILUMINATY™ BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
        INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE
        TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
        POSSIBILITY OF SUCH DAMAGES.{" "}
      </p>
      <h4>Contact Us</h4>
      <p>
        Please send any questions or comments (including all inquiries unrelated
        to copyright infringement) regarding this Website to
        gauri@WILUMINATY.com. Grievance officer In accordance with Information
        Technology Act 2000 and rules made there under, the name and contact
        details of the Grievance Officer are provided below: Mr. Abhinay
        Kareshiya Phone: +91- 7126555559 Email: claim@wiluminaty.com Time: Mon –
        Sat (9:00 – 18:00){" "}
      </p>
      <h4>POLICIES</h4>
      <h4> Profanity Policy</h4>
      <p>
        WILUMINATY™ prohibits the use of language that is racist, hateful,
        sexual or obscene in nature in a public area. Please report any
        violations of this policy to the correct area for review:{" "}
      </p>
      <p>• Report offensive Display Names</p>
      <p>
        {" "}
        • Report offensive language in a listing or otherwise If a feedback
        comment; or any communication made between Users on the Website; or
        email communication between Users in relation to transactions conducted
        on Website contain profanity, please review Our feedback removal policy
        and submit a request for action/removal. Disciplinary action may result
        in the indefinite suspension of a User’s account, temporary suspension,
        or a formal warning. WILUMINATY™ will consider the circumstances of an
        alleged policy violation and the user’s trading records before taking
        action. Violations of this policy may result in a range of actions,
        including:{" "}
      </p>
      <p>1. Limits placed on account privileges;</p>
      <p>2. Loss of special status;</p>
      <p> 3. Account suspension.</p>
      <h4>Replacement Guarantee*</h4>
      <p>
        {" "}
        The Replacement Guarantee seeks to assist Buyers who have been defrauded
        on the Website. If at the time of delivery and/or within specified days
        from the date of delivery of the product/s, if any defect is found, then
        the buyer of the product/s can ask for replacement of the product/s from
        WILUMINATY™. If WILUMINATY™ has suspicion or knowledge, that any of its
        buyers are involved in any activity that is intended to provide claims
        or information that is false, misleading or not genuine, then
        WILUMINATY™ may while reserving its rights to initiate civil and/or
        criminal proceedings against User may also at its sole discretion
        suspend, block, restrict, cancel the Display Name of such buyer and /or
        disqualify that User and any related Users from availing protection
        through this program. WILUMINATY™ reserves its right to initiate civil
        and/or criminal proceedings against a User who, files a invalid and/or
        false claims or provides false, incomplete, or misleading information.
        In addition to the legal proceedings as aforesaid, WILUMINATY™ may at
        its sole discretion suspend, block, restrict, cancel the Display Name
        [and its related Display Names] of such User and/or disqualify that User
        and any related Users from availing protection through this program. Any
        person who, knowingly and with intent to injure, defrauds or deceives,
        files a Fraudulent Complaint containing false, incomplete, or misleading
        information may be guilty of a criminal offence and will be prosecuted
        to the fullest extent of the law. For more details related to
        Replacement Policy, refer to http://www.wiluminaty.com/exchange-policy/
      </p>
      <h4>Returns Policy</h4>
      <p>
        {" "}
        Definition: ‘Return’ is defined as the action of giving back the item
        purchased by the Buyer to WILUMINATY™ website. Following situations may
        arise:{" "}
      </p>
      <p>1. Item was defective </p>
      <p>2. Item was damaged during the Shipping</p>
      <p> 3. Products was / were missing </p>
      <p>
        4. Wrong item was sent. Return could also result in refund of money in
        most of the cases. We encourage the Buyer to review the listing before
        making the purchase decision. In case Buyer orders a wrong item, Buyer
        shall not be entitled to any return/refund. Buyer need to raise the
        refund request within 10 days from the date of payment realization. Once
        Buyer has raised a return request by contacting Us on Our phone Number,
        WILUMINATY while closing the return ticket can select one of the
        following:
      </p>
      <p>
        {" "}
        1. Replace after shipment collection – WILUMINATY™ has agreed to wait
        for the logistics team to collect the shipment from the buyer before
        replacing it){" "}
      </p>
      <p>
        2. Refund after shipment collection – WILUMINATY™ has agreed to wait for
        the logistics team to collect the shipment from the buyer before
        refunding)
      </p>
      <p>
        {" "}
        3. Refund without shipment collection – WILUMINATY™ has agreed to refund
        the buyer without expecting the original shipment back)
      </p>
      <p>
        {" "}
        4. Replace without shipment collection – WILUMINATY™ has agreed to
        replace the order without expecting the original shipment back) In the
        event the WILUMINATY™ accepts the return request raised by the Buyer,
        Buyer will have to return the product and then the refund shall be
        credited to the Buyers account.
      </p>
      <h4>Replacement</h4>
      <p>
        {" "}
        Definition: Replacement is the action or process of replacing something
        in place of another. A Buyer can request for replacement whenever he is
        not happy with the item, reason being damaged in shipping, Defective
        item, Item(s) missing, wrong item shipped and the like. Buyer need to
        raise the replacement request within 10 days from the date of delivery
        of products. Once Buyer has raised a replacement request by contacting
        us on the Phone Number provided on the Website. Once the replacement
        request has been raised, the following steps shall be followed: 1. Buyer
        is asked for “Reason for Return”. Among others, the following are the
        leading reasons:
      </p>
      <p>• Shipping was damaged</p>
      <p> • Item was defective</p>
      <p>• Item Dead on Arrival</p>
      <p> • Item(s) were missing </p>
      <p>• Wrong item sent</p>
      <p>
        {" "}
        2. An intimation shall be provided to WILUMINATY™ seeking either
        “approval” or “rejection” of the replacement request.
      </p>
      <p>
        {" "}
        3. In case WILUMINATY™ accepts the replacement request, Buyer shall be
        required to return the product to WILUMINATY™ and only after return of
        the product; WILUMINATY™ shall be obliged to provide the replacement
        product to the Buyer. In case the Seller doesn’t have the product at
        all, WILUMINATY™ can provide the refund to the Buyer and Buyer shall be
        obligated to accept the refund in lieu of replacement. All the product
        parameters shall be required to be complied with in cases of
        replacement.{" "}
      </p>
      <h4>Email Abuse & Threat Policy</h4>
      <p>
        {" "}
        Private communication, including email correspondence, is not regulated
        by WILUMINATY™. WILUMINATY™ encourages its Users to be professional,
        courteous and respectful when communicating by email.
      </p>
      <p>
        {" "}
        However, WILUMINATY™ will investigate and can take action on certain
        types of unwanted emails that violate WILUMINATY™ policies. Such
        instances:
      </p>
      <p>
        {" "}
        Threats of Bodily Harm – WILUMINATY™ does not permit Users to send
        explicit threats of bodily harm. Misuse of WILUMINATY™ System –
        WILUMINATY™ allows Users to facilitate transactions through the
        WILUMINATY™ system, but will investigate any misuse of this service.
        Spoof (Fake) email – WILUMINATY™ will never ask you to provide sensitive
        information through email. In case you receive any spoof (fake) email,
        you are requested to report the same to us through ‘Contact Us’ tab.{" "}
      </p>
      <p>
        Spam (Unsolicited Commercial email) – WILUMINATY’s spam policy applies
        only to unsolicited commercial messages sent by WILUMINATY™ Users.
        WILUMINATY™ Users are not allowed to send spam messages to other Users.
      </p>
      <p>
        {" "}
        Offers to Buy or Sell Outside of WILUMINATY™ – WILUMINATY™ prohibits
        email offers to buy or sell listed products outside of the WILUMINATY
        Website. Offers of this nature are a potential fraud risk for both
        Buyers and Sellers.
      </p>
      <p>
        {" "}
        WILUMINATY™ policy prohibits user-to-user threats of physical harm via
        any method including, phone, email and on our public message boards.
        Violations of this policy may result in a range of actions, including:
      </p>
      <p></p>
      <p> • Limits on account privileges</p>
      <p> • Account suspension </p>
      <p>• Cancellation of listings </p>
      <p>• Loss of special status </p>
      <h4>Other Businesses</h4>
      <p>
        {" "}
        WILUMINATY™ does not take responsibility or liability for the actions,
        products, content and services on the Website, which are linked to
        Affiliates and / or third party websites using Website’s APIs or
        otherwise. In addition, the Website may provide links to the third party
        websites of our affiliated companies and certain other businesses for
        which, WILUMINATY™ assumes no responsibility for examining or evaluating
        the products and services offered by them. WILUMINATY™ does not warrant
        the offerings of, any of these businesses or individuals or the content
        of such third party website(s). WILUMINATY™ does not endorse, in any
        way, any third party website(s) or content thereof.
      </p>
    </div>
    </div>
  );
};

export default TermsAndUse;
