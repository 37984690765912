import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { UserContext } from './UserContext'; // Adjust the path as needed

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0;
  margin-top: 8em;
`;

const ProfileContainer = styled.div`
  display: flex;
  width: 80%;
  background-color: cadetblue;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #05313d;
  padding: 2em;
  width: 25%;
  text-align: center;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  width: 75%;
`;

const FormSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormColumn = styled.div`
  flex: 1;
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }
`;

const ProfileImageContainer = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 1em;
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0; /* Light grey background for empty avatar */
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FileInput = styled.input`
  display: none;
`;

const Name = styled.h2`
  margin: 0;
  color: black;
`;

const Email = styled.p`
  margin: 0;
  color: #888;
`;

const Title = styled.h3`
  margin-bottom: 1em;
  color: black;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

const Label = styled.label`
  margin-bottom: 0.5em;
  color: black;
`;

const Input = styled.input`
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SaveButton = styled.button`
  padding: 0.7em;
  background-color: #4c207b;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1em;

  &:hover {
    background-color: #3b1761;
  }
`;

const EditButton = styled.button`
  padding: 0.7em;
  background-color: #ff9800;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 1em;

  &:hover {
    background-color: #e68900;
  }
`;

const ProfileInfo = () => {
  const backgroundImageStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
    backgroundSize: 'cover',
    marginTop: '-9em',
    padding: '20px',
  };

  const { user } = useContext(UserContext); // Get user data from context

  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    state: '',
    area: '',
    email: '',
    education: '',
    country: '',
    region: '',
    Field: '',
    years: '',
    additionalDetails: '',
    resume: ''
  });

  const [isEditing, setIsEditing] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');


  useEffect(() => {
    if (user) {
      console.log("User data:", user); // Debug log
      const [firstName, ...lastName] = user.name.split(' ');
      setProfile((prevProfile) => ({
        ...prevProfile,
        firstName: firstName || '',
        lastName: lastName.join(' ') || '',
        email: user.email || ''
      }));
      setName(user.name);
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing((prevEditing) => !prevEditing);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile((prevProfile) => ({
          ...prevProfile,
          resume: reader.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleResumeClick = () => {
    document.getElementById('resumeInput').click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
    // Here, you can add code to save the profile changes to a server or local storage if needed
  };

  return (
    <div className='personalinfobackground' style={backgroundImageStyle}>
      <Container>
        <ProfileContainer>
          <LeftPanel>
            <ProfileImageContainer onClick={handleImageClick}>
              {profileImage ? (
                <ProfileImage src={profileImage} alt="Profile" />
              ) : (
                <ProfileImage src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" alt="Blank Profile" />
              )}
              <FileInput
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleImageChange}
              />
            </ProfileImageContainer>
            <Name>{name}</Name>
            <Email>{email}</Email>
          </LeftPanel>
          <RightPanel>
            <EditButton onClick={handleEdit}>
              {isEditing ? 'Cancel' : 'Edit'}
            </EditButton>
            <form onSubmit={handleSubmit}>
              <FormSection>
                <FormColumn>
                  <Title>Profile Information</Title>
                  <FormGroup>
                    <Label>First Name</Label>
                    <Input
                      type="text"
                      name="firstName"
                      value={profile.firstName}
                      onChange={handleChange}
                      placeholder="First Name"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Last Name</Label>
                    <Input
                      type="text"
                      name="lastName"
                      value={profile.lastName}
                      onChange={handleChange}
                      placeholder="Surname"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Mobile Number</Label>
                    <Input
                      type="text"
                      name="mobileNumber"
                      value={profile.mobileNumber}
                      onChange={handleChange}
                      placeholder="Enter phone number"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      value={profile.email}
                      onChange={handleChange}
                      placeholder="Enter email"
                      disabled
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Address Line 1</Label>
                    <Input
                      type="text"
                      name="addressLine1"
                      value={profile.addressLine1}
                      onChange={handleChange}
                      placeholder="Enter address line 1"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Address Line 2</Label>
                    <Input
                      type="text"
                      name="addressLine2"
                      value={profile.addressLine2}
                      onChange={handleChange}
                      placeholder="Enter address line 2"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Postcode</Label>
                    <Input
                      type="text"
                      name="postcode"
                      value={profile.postcode}
                      onChange={handleChange}
                      placeholder="Enter postcode"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>State</Label>
                    <Input
                      type="text"
                      name="state"
                      value={profile.state}
                      onChange={handleChange}
                      placeholder="Enter state"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Area</Label>
                    <Input
                      type="text"
                      name="area"
                      value={profile.area}
                      onChange={handleChange}
                      placeholder="Enter area"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                </FormColumn>
                <FormColumn>
                  <Title>Additional Information</Title>
                  <FormGroup>
                    <Label>Education</Label>
                    <Input
                      type="text"
                      name="education"
                      value={profile.education}
                      onChange={handleChange}
                      placeholder="Enter education details"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Country</Label>
                    <Input
                      type="text"
                      name="country"
                      value={profile.country}
                      onChange={handleChange}
                      placeholder="Enter country"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Region</Label>
                    <Input
                      type="text"
                      name="region"
                      value={profile.region}
                      onChange={handleChange}
                      placeholder="Enter region"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Field</Label>
                    <Input
                      type="text"
                      name="Field"
                      value={profile.Field}
                      onChange={handleChange}
                      placeholder="Enter field"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Years of Experience</Label>
                    <Input
                      type="text"
                      name="years"
                      value={profile.years}
                      onChange={handleChange}
                      placeholder="Enter years of experience"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Additional Details</Label>
                    <Input
                      type="text"
                      name="additionalDetails"
                      value={profile.additionalDetails}
                      onChange={handleChange}
                      placeholder="Enter additional details"
                      disabled={!isEditing}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Resume</Label>
                    <Input
                      type="text"
                      name="resume"
                      value={profile.resume}
                      placeholder="Upload Resume"
                      onClick={handleResumeClick}
                      readOnly
                      disabled={!isEditing}
                    />
                    <FileInput
                      type="file"
                      id="resumeInput"
                      accept=".pdf,.doc,.docx"
                      onChange={handleResumeChange}
                    />
                  </FormGroup>
                </FormColumn>
              </FormSection>
              {isEditing && <SaveButton type="submit">Save</SaveButton>}
            </form>
          </RightPanel>
        </ProfileContainer>
      </Container>
    </div>
  );
};

export default ProfileInfo;
