import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      navigate(`/search?q=${searchTerm}`);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  return (
    <header className="header">
      <nav className="navbar">
        
          
        <div className="navbar-logo">
          <video src="/Videos/logo.mp4" type="video/mp4" autoPlay loop muted />
        </div>
        <ul className="navbar-links">
          <li><a href="/home">Home</a></li>
          <li><a href="/About">About</a></li>
          <li className="dropdown-container" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <a href="/segment">Segment</a>
            <div className={`dropdown ${dropdownOpen ? 'open' : ''}`}>
              <ul>
                <li><a href="/Magazine"><img src="/Images/magazine.jfif" alt="Magazine Icon" className="dropdown-icon" /> Magazine</a></li>
                <li><a href="/NewsArticle"><img src="/Images/news.jfif" alt="News Icon" className="dropdown-icon" /> News and Articles</a></li>
                <li><a href="/Channel"><img src="/Images/channel.jfif" alt="Channel Icon" /> Channel</a></li>
                <li><a href="#2017"><img src="/Images/model.jfif" alt="Model Icon" className="dropdown-icon" /> Models</a></li>
                <li><a href="#2017"><img src="/Images/photographer.jpeg" alt="Photographer Icon" className="dropdown-icon" /> Photographer</a></li>
                <li><a href="#2017"><img src="/Images/designer.jfif" alt="Designer Icon" className="dropdown-icon" /> Designer</a></li>
              </ul>
              <ul>
              <li><a href="/HairStylist"><img src="/Images/hairtstylist.jfif" alt="Hair Stylist Icon" className="dropdown-icon" /> Hair Stylist</a></li>
              <li><a href="/Product"><img src="/Images/product.jfif" alt="Product Icon" className="dropdown-icon" /> Products</a></li>
                <li><a href="/MonthsTopper"><img src="/Images/monthstopper.jfif" alt="Month Topper Icon" className="dropdown-icon" /> Month's Topper</a></li>
                <li><a href="/Top10"><img src="/Images/top10.jfif" alt="Top 10 Icon" className="dropdown-icon" /> Top 10</a></li>
                <li><a href="/Top100"><img src="/Images/top100.jfif" alt="Top 100 Icon" className="dropdown-icon" /> Top 100</a></li>
                <li><a href="/WorldRecord"><img src="/Images/worldrecord.jfif" alt="World Record Icon" className="dropdown-icon" /> World Record</a></li>
                <li><a href="/survey"><img src="/Images/survey.jfif" alt="Survey Icon" className="dropdown-icon" /> Survey</a></li>
              </ul>
            </div>
          </li>
          <li><a href="/SocialMedia">Social Media</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
        <form className="search-form" onSubmit={handleSearch}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search..."
            className="search-input"
          />
          <button type="submit" className="search-button">Search</button>
          {searchTerm && (
            <button type="button" className="clear-button" onClick={clearSearch}>x</button>
          )}
        </form>
      </nav>
     
    </header>
  );
};

export default Header;
