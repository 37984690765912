import React, { useState } from 'react';
import axios from 'axios';
import './Donation.css'; // Create and import your styling file

const Donation = () => {
  const backgroundStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
       
    backgroundSize: 'cover',
    
    padding: '20px',
  };
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://wiluminatytv.com/create-donation-order', {
        name,
        email,
        phone,
        amount,
        currency: 'INR'
      });

      const { payment_link } = response.data;
      if (payment_link) {
        window.location.href = payment_link; // Redirect to Cashfree payment page
      } else {
        throw new Error('Payment link not found');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Payment failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='donationbackground' style={backgroundStyle} >
    <div className="donation-container">
      <h2>Donate Now</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label>Phone:</label>
          <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
        <div>
          <label>Amount:</label>
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : 'Donate'}
        </button>
      </form>
    </div>
    </div>
  );
};

export default Donation;
