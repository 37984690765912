import React, { useState } from 'react';
import axios from 'axios';
import './Investment.css'; // Create and import your styling file

const Investment = () => {
  const backgroundStyle = {
    backgroundImage: 'url(/Images/orange1.jpg)',
    backgroundSize: 'cover',
    padding: '20px',
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post('https://wiluminatytv.com/create-investment-order', {
        name,
        email,
        phone,
        amount,
        currency: 'INR'
      });

      const { payment_link } = response.data;
      if (payment_link) {
        window.location.href = payment_link; // Redirect to Cashfree payment page
      } else {
        throw new Error('Payment link not found');
      }
    } catch (error) {
      console.error('Error creating order:', error);
      alert('Payment failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='investmentbackground'>
    <div className='investment-background' style={backgroundStyle}>
      <div className="investment-container">
        <h2>Discover Your Dream Profit with Wiluminaty!</h2>
        <p>🏡 <strong>Business Name:</strong> TRFALILE LIMITED</p>
        <p>🌇 <strong>Brand Name:</strong> WILUMINATY & WILUMINATY TV</p>
        <p>💰💰 You can do MINIMUM INVESTMENT as per your choice!</p>
        <h3>🌟 Why Choose The Wiluminaty?</h3>
        <ul>
          <li><strong>SAFE 3X RETURNS:</strong> Anticipate tripling your investment in 3 years (working to bring SME IPO).</li>
          <li><strong>SEGMENT:</strong> Fashion & Lifestyle Products and Media House.</li>
          <li><strong>PRODUCTS CATEGORY:</strong> Apparels, Footwear, Accessories & Wellness products, Online Fashion Channel, Fashion website, Webseries (shows), Fashion survey, Fashion Calender, OTT platform, Newspaper and Magazine, Television Channel.</li>
          <li><strong>TARGET:</strong> Approaching 3 Billion populations globally within 5 years and targeting minimum of minimum 1% conversion and targeting minimum 2000 rs. Business Transaction from 1 Customer within 5 years.</li>
        </ul>
        <h3>🚀 EXPECTING TURNOVER:</h3>
        <p>Expecting 3 crores of real customers in next 5 years who will give minimum order of 2000 rs. which leads to business of 6000 Crores in next 5 years.</p>
        <h3>🔍 FIND US ON:</h3>
        <ul>
          <li><a href="http://www.wiluminaty.in/search?srchval=wiluminaty&city=Nagpur" target="_blank" rel="noopener noreferrer">Company Website</a></li>
          <li><a href="https://bit.ly/Flipkart-Wiluminaty" target="_blank" rel="noopener noreferrer">Wiluminaty-Flipkart</a></li>
          <li><a href="https://bit.ly/Amazon-Wiluminaty" target="_blank" rel="noopener noreferrer">Wiluminaty-Amazon</a></li>
          <li><a href="https://bit.ly/JioMart-Wiluminaty" target="_blank" rel="noopener noreferrer">Wiluminaty-JioMart</a></li>
          <li><a href="https://www.facebook.com/wiluminaty" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://www.instagram.com/wiluminatylifestyle/" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          <li><a href="https://twitter.com/wiluminaty" target="_blank" rel="noopener noreferrer">Twitter</a></li>
          <li><a href="https://www.linkedin.com/company/wiluminaty" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        </ul>
        <h3>📞 CONTACT US TODAY!</h3>
        <p>Don't Miss This Golden Opportunity To Invest In WILUMINATY.<br />
        Abhinaye Kareshiya<br />
        +91-7208601619</p>
        
        <form onSubmit={handleSubmit}>
          <div>
            <label>Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
          </div>
          <div>
            <label>Email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <label>Phone:</label>
            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} required />
          </div>
          <div>
            <label>Amount:</label>
            <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Invest'}
          </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default Investment;
