import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import './Product.css';

// Define the move right to left and left to right animation
const moveRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const moveLeftToRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: -134px;
  background: url('/Images/orange1.jpg') no-repeat center center;
  background-size: cover;
  padding: 20px;
`;

const HeroSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  height: 600px;
  color: white;
`;

const HeroContent = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  margin-top: 10em; /* Hide overflow for smooth animation */
  padding: 20px;
  border-radius: 8px;
`;

const HeroItem = styled.div`
  text-align: center;
  flex: 1;
  margin: 10px;
  overflow: hidden;
`;

const HeroImageRightToLeft = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  animation: ${moveRightToLeft} 10s linear infinite;
`;

const HeroImageLeftToRight = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  animation: ${moveLeftToRight} 10s linear infinite;
`;

const HeroText = styled.div`
  margin-top: 10px;
`;

const SectionTitle = styled.h2`
  text-align: center;
  margin: 40px 0;
`;

const Categories = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
`;

const Category = styled.a`
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: background 0.3s;

  &:hover {
    background: #f0f0f0;
  }
`;

const ProductGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

const ProductCard = styled.div`
  width: 200px;
  text-align: center;
  background: chocolate;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  margin-bottom: 20px; /* Add margin to create space between cards */

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
`;

const ProductVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
`;

const ProductTitle = styled.h3`
  margin: 10px 0;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 34px;
  margin-bottom: 10em;
  margin-left: 2em;
`;

const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  right: 0;
  background-color: coral;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 4px;
  margin-top: -21em;
`;

const DropdownItem = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: #d3d3d3;
  }
`;

const DownloadButton = styled.a`
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-decoration: none;
  color: black;
  transition: background 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Product = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [filter, setFilter] = useState('ALL');

  const products = [
    { title: '', image: '/Images/img.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img2.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img3.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img4.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img5.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img6.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img7.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img8.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img9.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img10.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img11.png', type: 'IMAGE' },
    { title: '', image: '/Images/img12.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img13.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img14.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img15.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img16.jpg', type: 'IMAGE' },
    { title: '', image: '/Images/img17.jpg', type: 'IMAGE' },
    { title: '', image: '/Videos/video.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video1.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video2.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video3.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video4.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video5.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video6.mp4', type: 'VIDEO' },
    { title: '', image: '/Videos/video7.mp4', type: 'VIDEO' },
  ];

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const filterProducts = (type) => {
    setFilter(type);
    setShowDropdown(false);
  };

  const filteredProducts = products.filter(
    (product) => filter === 'ALL' || product.type === filter
  );

  return (
    <Container>
      <HeroSection>
        <HeroContent>
          <HeroItem>
            <HeroImageRightToLeft src="/Images/productimg.jpg" alt="Product 1" />
          </HeroItem>
          <HeroItem>
            <HeroImageLeftToRight src="/Images/productimg2.jpg" alt="Product 2" />
          </HeroItem>
          <HeroItem>
            <HeroImageRightToLeft src="/Images/productimg3.jpg" alt="Product 3" />
          </HeroItem>
        </HeroContent>
        <DropdownContainer>
          <DropdownButton onClick={toggleDropdown}>⋮⋮⋮</DropdownButton>
          <DropdownMenu show={showDropdown}>
            <DropdownItem onClick={() => filterProducts('ALL')}>ALL</DropdownItem>
            <DropdownItem onClick={() => filterProducts('PDF')}>PDF</DropdownItem>
            <DropdownItem onClick={() => filterProducts('IMAGE')}>IMAGES</DropdownItem>
            <DropdownItem onClick={() => filterProducts('GIF')}>GIF</DropdownItem>
            <DropdownItem onClick={() => filterProducts('VIDEO')}>VIDEOS</DropdownItem>
          </DropdownMenu>
        </DropdownContainer>
      </HeroSection>
      <SectionTitle>Product Gallery</SectionTitle>
      <ProductGallery>
        {filteredProducts.map((product, index) => (
          <ProductCard key={index}>
            {product.type === 'IMAGE' ? (
              <>
                <ProductImage src={product.image} alt={product.title} />
                <DownloadButton href={product.image} download>
                  Download
                </DownloadButton>
              </>
            ) : (
              <ProductVideo controls>
                <source src={product.image} type="video/mp4" />
                Your browser does not support the video tag.
              </ProductVideo>
            )}
            {product.title && <ProductTitle>{product.title}</ProductTitle>}
          </ProductCard>
        ))}
      </ProductGallery>
    </Container>
  );
};

export default Product;
