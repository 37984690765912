// src/components/Contact.js
import React from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faHome, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Contact = () => {
    const backgroundImageStyle = {
      backgroundImage: 'url(/Images/orange1.jpg)',
       
      backgroundSize: 'cover',
      
      padding: '20px',
    };
      return (
        <div style={backgroundImageStyle} className="contact-page">      
        <div className="contact-header">
        <h1>Contact Us</h1><br/><br/>
        <p>We'd love to hear from you! Whether you have a question about features, trials, pricing, need a demo, or anything else, our team is ready to answer all your questions.</p><br/><br/>
        <h2 className='getintouchh2'>Get in Touch</h2>
      <h4 className='getintouchh4'>You can reach us through the following methods:</h4>

      </div>
      <div className="contact-info">
        <div className="contact-item">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <p>Nagpur, Maharashtra 440009</p>
        </div>
        <div className="contact-item">
          <FontAwesomeIcon icon={faHome} />
          <p>www.wiluminaty.com</p>
        </div>
        <div className="contact-item">
          <FontAwesomeIcon icon={faEnvelope} />
          <p>wiluminatymedia@gmail.com</p>
        </div>
        <div className="contact-item">
          <FontAwesomeIcon icon={faPhone} />
          <p>+91-7276961619</p>
        </div>
      </div>
      
    </div>
  );
};

export default Contact;
