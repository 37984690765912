import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  margin-top: -21em;
  color: white;
  margin-right: 3em;
`;

const ProfileButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: #333;
  color: white;
  border: 1px solid #555;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const DropdownHeader = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  background: #444;
  border-bottom: 1px solid #555;
`;

const InitialsAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #00c2cb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-size: 20px;
`;

const Greeting = styled.div`
  flex-grow: 1;
`;

const Name = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Email = styled.div`
  font-size: 14px;
  color: #ccc;
`;

const DropdownItem = styled.div`
  padding: 15px;
  cursor: pointer;
  background-color: #333;
  color: white;

  &:hover {
    background-color: #555;
  }
`;

const Footer = styled.div`
  padding: 15px;
  font-size: 12px;
  color: #ccc;
  border-top: 1px solid #555;
  display: flex;
  justify-content: space-between;
  background-color: #333;
`;

const Popup = styled.div`
  width: 600px;
  display: flex;
  flex-direction: row;
  background: linear-gradient(135deg, #ff8c00 60%, #e9692c 40%);
  border-radius: 40px;
  overflow: hidden;
  position: relative;
   margin-top: -11em; /* Adjusted from -12em to be more visible */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
`;

const FormContainer = styled.div`
  flex: 1;
  padding: 2em;
  background: #d2691e;
  border-radius: 15px 0 0 15px;
`;

const WelcomeContainer = styled.div`
  flex: 1;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 0 15px 15px 0;
  text-align: center;
`;

const Title = styled.h2`
  color: #0d1b2a;
  margin-bottom: 20px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  margin-bottom: 15px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #00c2cb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #009ea6;
  }
`;

const Link = styled.a`
  margin-top: 20px;
  color: #00c2cb;
  cursor: pointer;
  display: block;
  text-align: center;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
`;

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [isVisible, setIsVisible] = useState(true); // Initially set to true to show the form
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [userProfile, setUserProfile] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setError('');
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateName = (name) => {
    const re = /^[a-zA-Z\s]+$/;
    return re.test(String(name));
  };

  const validateContact = (contact) => {
    const re = /^[0-9]{10}$/;
    return re.test(String(contact));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Validation logic
    if (!formData.email || !formData.password) {
      setError('Email and Password are required.');
      return;
    }

    if (!validateEmail(formData.email)) {
      setError('Invalid email format.');
      return;
    }

    if (!isLogin) {
      if (!formData.name || !formData.contact || !formData.confirmPassword) {
        setError('All fields are required for sign up.');
        return;
      }

      if (!validateName(formData.name)) {
        setError('Name should contain only letters and spaces.');
        return;
      }

      if (!validateContact(formData.contact)) {
        setError('Contact should contain exactly 10 digits.');
        return;
      }

      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match.');
        return;
      }
    }

    try {
      const endpoint = isLogin ? 'login' : 'signup';
      const response = await axios.post(`http://localhost:3001/${endpoint}`, {
        ...formData
      });

      if (response.data.message === 'Login successful' || response.data.message === 'Signup successful') {
        alert(response.data.message);
        const name = formData.name || response.data.name; // Get the name either from the form or response
        setUserProfile({ email: formData.email, name: name });
        setIsVisible(false); // Close the login form
        navigate('/home'); // Navigate to the home page
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred. Please try again.');
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleLogout = () => {
    setUserProfile(null);
    setIsVisible(true);
    localStorage.removeItem('popupShown');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const getInitials = (name) => {
    if (!name) return '';
    return name.split(' ').map((n) => n[0]).join('').toUpperCase();
  };

  return (
    <div>
      {userProfile && (
        <Nav>
          <ProfileButton onClick={toggleDropdown}>
            <InitialsAvatar>{getInitials(userProfile.name)}</InitialsAvatar>
            <Dropdown isOpen={dropdownOpen}>
              <DropdownHeader>
                <InitialsAvatar>{getInitials(userProfile.name)}</InitialsAvatar>
                <Greeting>
                  <Name>{userProfile.name}</Name>
                  <Email>{userProfile.email}</Email>
                </Greeting>
              </DropdownHeader>

              <DropdownItem onClick={() => navigate('/PersonalInfo')}>
                Personal Profile
              </DropdownItem>
              <DropdownItem onClick={() => alert('Manage your Google Account')}>
                Manage your Google Account
              </DropdownItem>
              <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              <Footer>
                <span>Privacy Policy</span>
                <span>Terms of Service</span>
              </Footer>
            </Dropdown>
          </ProfileButton>
        </Nav>
      )}

      {isVisible && (
        <Container>
          <Popup>
            <CloseButton onClick={handleClose}>×</CloseButton>
            <FormContainer>
              <Title>{isLogin ? 'Login' : 'Sign Up'}</Title>
              <form onSubmit={handleSubmit}>
                {!isLogin && (
                  <>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <Input
                      type="text"
                      name="contact"
                      placeholder="Contact"
                      value={formData.contact}
                      onChange={handleChange}
                    />
                  </>
                )}
                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {!isLogin && (
                  <Input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                )}
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <Button type="submit">{isLogin ? 'Login' : 'Sign Up'}</Button>
              </form>
              <Link onClick={toggleForm}>
                {isLogin
                  ? "Don't have an account? Sign Up"
                  : 'Already have an account? Login'}
              </Link>
            </FormContainer>
            <WelcomeContainer>
              <h2>Welcome to WiluminatyTV+</h2>
              <p>
                {isLogin
                  ? 'Please login to continue.'
                  : 'Sign up to access all features.'}
              </p>
            </WelcomeContainer>
          </Popup>
        </Container>
      )}
    </div>
  );
};

export default Login;
