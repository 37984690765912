import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-top: -9em;

  @media (max-width: 1200px) {
    margin-top: -6em;
  }

  @media (max-width: 992px) {
    margin-top: -4em;
  }

  @media (max-width: 768px) {
    margin-top: -2em;
  }

  @media (max-width: 576px) {
    margin-top: -9em;
  }
`;

const Header = styled.header`
  background: url(/Images/careername1.jpg) no-repeat center center;
  background-size: cover;
  padding: 4em 0;
  text-align: center;
  margin-bottom: 2em;
  margin-top: 14em;
  padding-left: 40em;
  height: 15em;
  margin-left: 38em;

  @media (max-width: 1200px) {
    padding-left: 30em;
    margin-left: 30em;
  }

  @media (max-width: 992px) {
    padding-left: 20em;
    margin-left: 20em;
  }

  @media (max-width: 768px) {
    margin-top: 7em;
    padding-left: 2em;
    margin-left: 0;
    height: auto;
    padding: 2em;
  }

  @media (max-width: 576px) {
    margin-top: 10em;
    padding-left: 1em;
    padding: 1em;
            width: 24em;
        height: 14em;
    }
  }
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  color: #333;
  margin-top: -22em;
  width: 38%;
  margin-right: 40em;

  @media (max-width: 1200px) {
    margin-right: 30em;
    width: 50%;
  }

  @media (max-width: 992px) {
    margin-right: 20em;
    width: 60%;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    margin-top: 0;
    width: 80%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 2em;
  }

  @media (max-width: 576px) {
    font-size: 0.9em;
    width: 90%;
    margin-bottom: 1em;
  }
`;

const Button = styled.button`
  padding: 1em 2em;
  margin-top: 1em;
  background-color: #cc5500;
  color: white;
  border: none;
  border-radius: 49px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darkred;
  }

  @media (max-width: 1200px) {
    padding: 0.9em 1.8em;
  }

  @media (max-width: 992px) {
    padding: 0.8em 1.6em;
  }

  @media (max-width: 768px) {
    padding: 0.7em 1.4em;
    font-size: 0.9em;
  }

  @media (max-width: 576px) {
    padding: 0.6em 1.2em;
    font-size: 0.8em;
  }
`;

const Instructions = styled.div`
  margin-bottom: 2em;
  background-color: #ffcc99;
  padding: 1em;
  border-radius: 5px;
  color: black;
  font-size: 0.9em;
  line-height: 1.5;

  @media (max-width: 1200px) {
    padding: 0.9em;
  }

  @media (max-width: 992px) {
    padding: 0.8em;
  }

  @media (max-width: 768px) {
    padding: 0.7em;
  }

  @media (max-width: 576px) {
    padding: 0.6em;
  }
`;

const FormSection = styled.div`
  width: 80%;
  text-align: left;
  background-color: #ffcc99;
  padding: 2em;
  border-radius: 10px;
  margin-top: 10em;
  margin-bottom: 2em;

  @media (max-width: 1200px) {
    width: 85%;
  }

  @media (max-width: 992px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 95%;
    margin-top: 2em;
    padding: 1em;
  }

  @media (max-width: 576px) {
    padding: 0.5em;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
`;

const FormColumn = styled.div`
  flex: 1;
  min-width: 200px;
  margin-right: 1em;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 1em;
  }

  @media (max-width: 576px) {
    min-width: 150px;
  }
`;

const Label = styled.label`
  margin-bottom: 0.5em;
  font-weight: bold;
  color: black;
`;

const Input = styled.input`
  width: 90%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    padding: 0.4em;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 100px;
`;

const VideoSection = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;

  video {
    width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    @media (max-width: 1200px) {
      width: 85%;
    }

    @media (max-width: 992px) {
      width: 90%;
    }

    @media (max-width: 768px) {
      width: 95%;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;

const backgroundImageStyle = {
  backgroundImage: "url(/Images/orange1.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "left left",
  backgroundRepeat: "no-repeat",
  height: "auto",
  color: "white",
  width: "100%",
};

const vacancies = [
  'HR Executive',
  'Company Secretary',
  'Chartered Accountant',
  'Social Media Handler',
  'E-Commerce Market Place Handler',
  'Export Manager',
  'Back Office Assistant',
  'Data Entry Executive',
  'Tele-Caller Executive',
  'Delivery Executive',
  'Jr. Accountant',
  'Purchase Manager',
  'Photographer',
  'Animation Designer',
  'Graphic Designer',
  'Classified Media (Website) Handler',
  'Online PR & Branding',
  'Online Marketing Executive',
  'Social Media Marketing',
  'S.E.O. Expert',
  'Chief Editor (Magazine)',
  'Chief Editor (You Tube)',
  'Strategy Manager',
  'Quality Checker Manager',
  'Art Director',
  'Creative Director',
  'Design Director',
  'Fashion Designers',
  'Picture Editor',
  'Copy Editor',
  'Proof Readers',
  'Production Manager',
  'Department Editor',
  'Writer',
  'Sales Manager',
  'Marketing Manager',
  'Publication Manager',
  'Working Partners',
  'Digitize',
  'Keyword, Hashtags, Content Writer',
  'Website Handler',
  'Website Developer',
  'ERP + CRM Developer',
  'Strategy Planner',
  'Videographer',
  'Video Editor',
  'Male & Female Models',
  'Influencer',
  'Fashion Reporter',
  'Ads.Sales Manager',
  'Administrative Assistant',
  'Blogger',
  'Vlogger',
  'Public Relation Manager',
  'Account Manager',
  'Financial Director',
  'Executive Finance Department',
  'Analytics Director',
  'Business Development Manager',
  'Printing Press Agency',
  'Reporter',
  'Social Media Manager',
  'Shoot Director',
  'Shoot Producer',
  'Story Writer',
  'Anchors',
  'Credit Manager',
  'Recovery Boy',
  'Sales Executive',
  'Advocate',
  'Team Leader',
  'Tele-Caller Customer Care',
  'Strategy Expert- Finance',
  'Operation Manager',
  'Volunteer',
  'Chief Executive Officer',
  'Account Executive',
  'Others (Not Mention Above)',
];

const Career = () => {
  const [showForm, setShowForm] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    currentRole: '',
    desiredRole: '',
    resume: null,
    coverLetter: '',
    additionalInfo: '',
    experience: '',
    startDate: '',
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    setFormData({
      name: '',
      email: '',
      phone: '',
      currentRole: '',
      desiredRole: '',
      resume: null,
      coverLetter: '',
      additionalInfo: '',
      experience: '',
      startDate: '',
    });
  };

  return (
    <Container style={backgroundImageStyle}>
      {!showForm && <Header></Header>}
      {showForm ? (
        <FormSection>
          <h1 style={{ color: 'black', marginLeft: '10em' }}>Candidate Information Form</h1>
          <Instructions>
            <h3>Please Read These Instructions Carefully</h3>
            <ol>
              <li>Read the entire application form before starting.</li>
              <li>Be fill as neat as possible.</li>
              <li>Complete all pages of Application.</li>
              <li>In case of incomplete or illogical information, those application may not be considered.</li>
              <li>All the questions should be answered carefully.</li>
            </ol>
          </Instructions>
          <FormGroup>
            <Label>Choose Vacancies as per your skill set</Label>
            {vacancies.map((vacancy, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={vacancy}
                  name="desiredRole"
                  value={vacancy}
                  checked={formData.desiredRole === vacancy}
                  onChange={handleChange}
                />
                <Label htmlFor={vacancy}>{vacancy}</Label>
              </div>
            ))}
          </FormGroup>
          <form onSubmit={handleSubmit}>
            <h3 style={{ color: 'black' }}>Personal Information</h3>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="name">Full Name</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="address">Corresponding Address</Label>
                <Input
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="phone">Contact Number</Label>
                <Input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="tel">Telephone Number</Label>
                <Input
                  type="tel"
                  id="tel"
                  name="tel"
                  value={formData.tel}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="dob">Date of Birth</Label>
                <Input
                  type="date"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="maritalStatus">Marital Status</Label>
                <Input
                  type="text"
                  id="maritalStatus"
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="bloodGroup">Blood Group</Label>
                <Input
                  type="text"
                  id="bloodGroup"
                  name="bloodGroup"
                  value={formData.bloodGroup}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="domicile">Domicile (State)</Label>
                <Input
                  type="text"
                  id="domicile"
                  name="domicile"
                  value={formData.domicile}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="nationality">Nationality</Label>
                <Input
                  type="text"
                  id="nationality"
                  name="nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="religion">Religion</Label>
                <Input
                  type="text"
                  id="religion"
                  name="religion"
                  value={formData.religion}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="cnic">CNIC Number</Label>
                <Input
                  type="text"
                  id="cnic"
                  name="cnic"
                  value={formData.cnic}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <h3 style={{ color: 'black' }}>Academic Information</h3>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="academic">Last Academic Degree</Label>
                <Input
                  type="text"
                  id="academic"
                  name="academic"
                  value={formData.academic}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="passingYear">Year of Passing</Label>
                <Input
                  type="text"
                  id="passingYear"
                  name="passingYear"
                  value={formData.passingYear}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <FormColumn>
                <Label htmlFor="institute">Last Attended Institute</Label>
                <Input
                  type="text"
                  id="institute"
                  name="institute"
                  value={formData.institute}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
              <FormColumn>
                <Label htmlFor="cgpa">CGPA / Percentage</Label>
                <Input
                  type="text"
                  id="cgpa"
                  name="cgpa"
                  value={formData.cgpa}
                  onChange={handleChange}
                  required
                />
              </FormColumn>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="experience">Experience</Label>
              <TextArea
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="startDate">Start Date</Label>
              <Input
                type="date"
                id="startDate"
                name="startDate"
                value={formData.startDate}
                onChange={handleChange}
                required
              />
            </FormGroup>
           
            <FormGroup>
              <Label htmlFor="resume">Upload Resume</Label>
              <Input
                type="file"
                id="resume"
                name="resume"
                accept=".pdf,.doc,.docx"
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="additionalInfo">Additional Information</Label>
              <TextArea
                id="additionalInfo"
                name="additionalInfo"
                value={formData.additionalInfo}
                onChange={handleChange}
              />
            </FormGroup>
            <Button type="submit">Submit</Button>
          </form>
        </FormSection>
      ) : (
        <>
        <Subtitle>
            <h2>Join our team</h2>
            Lear's track record of talented professionals working together started early last century and we’ve built our leading global success on collaborative talents dedicated to being the best team in the business. Explore below to learn what motivates greatness.
            <h2>Job Openings</h2>
            If you crave challenging work, ongoing opportunity for career growth, and rewards that make it all worthwhile, Lear has the job you are looking for. &nbsp;&nbsp;
            <Button onClick={() => setShowForm(true)}>Fill This Form</Button>
          </Subtitle>
          <VideoSection>
            <video controls autoPlay muted loop>
              <source src="/videos/aboutus.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </VideoSection>
          </>
        )}
    </Container>
  );
};

export default Career;
