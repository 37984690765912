import React from 'react';
import './InviteAndEarn.css'

const InviteAndEarn = () => {
    const backgroundImageStyle = {
        backgroundImage: 'url(/Images/orange1.jpg)',
       
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '20px',
      
      };
  return (
    <div className="invite-and-earn" style={backgroundImageStyle}>
      <h1 style={{marginTop:'7em'}}>FAQs</h1>
      
      <h2>How does 'Invite & Earn' work?</h2>
      <p>You and your friend can earn a Rs 100 off an order of Rs 1000. Please follow the below steps:</p>
      <ol>
        <li>Share your code with a friend.</li>
        <li>Your friend installs the app & enters your code.</li>
        <li>Your friend makes their first purchase of Rs 500 or more & doesn't cancel or return.</li>
        <li>
          You and your friend both receive a Rs 100 off an order of Rs 1000, post the successful completion of your friend's first purchase post the return period & may take more than 30 days.
        </li>
        <li>Offer is only valid if your friend is a new WILUMINATY TV user & subject to other terms and conditions.</li>
      </ol>
      <p>
        Your friend must apply your code on installation & meet the conditions below in order for either of you to get a reward.
        It may take more than 30 days for the referrer to receive the reward as the reward will be provided to the referrer only after the expiry of the return/ cancellation period for products.
        The reward amounts are subject to change & rewards earned will be based on the amounts offered in the active campaign period when the referral takes place.
      </p>

      <h2>What are the conditions to earning this reward?</h2>
      <p>Your friend (Referee) has to install the WILUMINATY TV app and should be:</p>
      <ul>
        <li>New user who has never had a WILUMINATY TV account.</li>
        <li>An Indian phone number should be linked to your friend's account that has not been used with someone else's account.</li>
        <li>Your friend has installed the WILUMINATY TV app on a new device which was never used to download the WILUMINATY TV app.</li>
        <li>Apply the referral code in 30 days from their first login on the device.</li>
      </ul>
      <p>Your friend (Referee) should:</p>
      <ul>
        <li>Make a purchase of min. Rs. 500 in a single transaction while the program is still active.</li>
        <li>Not buy any electronic gift vouchers (eGVs).</li>
        <li>Not cancel/return any of the product(s) ordered as part of the purchase.</li>
      </ul>
      <p>You must have a valid WILUMINATY TV account which has a valid Indian mobile number linked to it.</p>
      <p>This is a subset of the conditions and comprehensive details on the terms and conditions for this offer are listed below.</p>
      <p>All rewards are given post the successful completion of the qualifying purchase by the Referee as outlined above.</p>

      <h2>How many rewards can I earn?</h2>
      <p>You can earn up to 4 rewards (1 if you accepted someone else's referral invite & 3 rewards for referring your friends). Each one of your referred friends would earn a reward themselves.</p>
      <p>Your rewards will appear in 'My Rewards section' accessed from My Account and also be sent via an in-app notification and an email and push notification.</p>

      <h2>TERMS AND CONDITIONS FOR THE INVITE AND EARN PROGRAM</h2>
      <p>Trfalile Limited (hereinafter referred to as "WILUMINATY TV", "We", "Our", "Us") is offering discount vouchers under its Invite & Earn Program ("Offer") from November 28th, 2017 (00:00 HRS) wherein the user has an opportunity to participate subject to the terms of this Policy mentioned below ("Policy").</p>
      <ol>
        <li>By participating in this Offer, You agree to be bound by the terms and Conditions of this Policy, the Terms of Use, Privacy Policy and other relevant documentation that are available on www.Wiluminatytv.com including any modifications, alterations or updates that We make.</li>
        <li>This is a limited period offer and is subject to change, at the sole discretion of WILUMINATY TV.</li>
        <li>To be eligible for the Offer, You (Referrer) need to fulfill the following criteria:
          <ul>
            <li>You must have a valid account on the WILUMINATY TV App.</li>
            <li>Your account must be mobile number verified with an Indian phone number on WILUMINATY TV App. If Your account is not verified via an Indian mobile number then you can do the same within 30 days of sending an invite else you won't be eligible for the Offer.</li>
          </ul>
        </li>
        <li>Details of the Offer:
          <ol>
            <li>To participate in the Offer, You must invite people who are not existing users of the WILUMINATY TV App ("Referee") to download the WILUMINATY TV App.</li>
            <li>The Referee must click on the link sent by You, successfully download the WILUMINATY TV App using OTP verification to login to their WILUMINATY TV App.</li>
            <li>If the Referee has downloaded the WILUMINATY TV App through other means, they can use the referral code in Invite & Earn section in the App menu. The code is to be entered in the Rewards page of the Invite & Earn section.</li>
            <li>The device on which the Referee downloads the WILUMINATY TV App must be a new device (must not have installed the WILUMINATY TV App earlier). The Referee must be a new user and the mobile number should be an Indian mobile number which has not been registered before with any WILUMINATY TV account.</li>
            <li>The Referee has up to 30 days from their first login date to enter their phone number as part of the account details in order to be eligible for this program apart from entering the referral code.</li>
            <li>User Mobile Number should be registered in India, foreign mobile numbers are not allowed.</li>
            <li>If the Referee fulfills all the above conditions he is eligible for the referral program. The Referee needs to then complete a successful purchase of Rs 500 in a single transaction during the subsistence of the referral program. Once the return period for the purchase has expired and the product has not been cancelled or returned within such time, the Referee & Referrer will receive a discount voucher of Rs. 100 off on a minimum purchase of Rs. 1000. These rewards are subject to the referral program still being in subsistence at such point in time.</li>
            <li>Details of the incentives provided are visible under 'My Rewards' section which is accessed from 'My Account' would be communicated to You through an In-app Notification and email (if available) on the WILUMINATY TV App. The Rewards awarded to the referee and the referrer can be accessed from the My Rewards section through the App.</li>
            <li>You may refer unlimited number of people however, You shall be eligible only for a maximum of 3 Rewards for referring new users.</li>
            <li>Rewards are non-transferable and cannot be redeemed against cash, credit or be used to buy WILUMINATY TV Gift Cards (Electronic Gift Vouchers or eGVs) or Gold coins & bars. These can only be used to purchase products listed on WILUMINATY TV. Purchasing these products or transferring rewards in any way will lead to punitive actions by WILUMINATY TV.</li>
            <li>Additional terms and conditions of the reward are listed in the WILUMINATY TV App and can be accessed in the Reward's T&Cs.</li>
          </ol>
        </li>
        <li>We reserve the right, at our sole discretion, to change, modify, add or remove portions of this Policy, including without limitation: changing the amount of Rewards, modifying how You may earn and spend Rewards earned, modifying the duration and expiration of Rewards, minimum purchase amounts for use of Rewards, maximum amount of Rewards that You may earn, and discontinuing the Offer entirely, at any time without any prior written notice to You.</li>
        <li>WILUMINATY TV shall not be responsible for any products purchased using the Rewards. This will strictly be the respective Seller's responsibility.</li>
        <li>You hereby agree and understand that in order to participate in the Offer, You may be required to submit personal information, about You and the Customers you refer, such as name, mobile number and email address. You further agree to receive communications from us with regard to your participation in the Offer. Any information collected from You or the Referred Customers as part of the Offer shall be subject to WILUMINATY TV's Privacy Policy.</li>
        <li>WILUMINATY TV reserves the right to take any administrative and / or legal action including but not limited to termination of Your eligibility for the Offer or terminate Your User account, or the accounts of Referred Customers, if You are found:
          <ul>
            <li>Opening multiple accounts with different email addresses, for the same person/yourself in order to generate additional Rewards;</li>
            <li>Sending invites to people using spam, display advertising, sponsored links, unsolicited e-mails, or links on message boards or forums;</li>
            <li>Placing large orders, earning and redeeming Rewards and then returning a major part of the order placed.</li>
            <li>Making multiple successful referrals and redeeming the Rewards and then a majority of your Referred Customers return the orders placed using the Rewards.</li>
            <li>Attempting to take/taking unfair advantage of the Offer.</li>
            <li>Using false names, impersonating other people, or otherwise providing false or misleading information to Us;</li>
            <li>Offering incentives to people who do not have existing accounts with WILUMINATY TV to register with WILUMINATY TV;</li>
            <li>Violating any terms of this Policy or any other terms and conditions on WILUMINATY TV, including but not limited to Reward Terms in any way; or</li>
            <li>Participating in the Offer in contravention with any applicable law or regulations.</li>
            <li>Purchasing or receiving WILUMINATY TV Gift Cards (GCs) bought using these Rewards.</li>
          </ul>
        </li>
        <li>This Offer shall be applicable only in certain pin codes. Therefore, if You or the referee belong to pin codes which have been blacklisted, this Offer shall not be applicable.</li>
        <li>You are not bound in any way to participate in the Offer and any such participation is voluntary.</li>
        <li>The Offer shall be subject to force majeure events and on occurrence of such an event, the Offer may be withdrawn at the discretion of WILUMINATY TV.</li>
        <li>WILUMINATY TV reserves the right, in its sole discretion, to disqualify any participant that tampers or attempts to tamper with the Offer or violates the Policy.</li>
        <li>WILUMINATY TV shall not be responsible for any loss, injury or any other liability arising due to participation by any person in the Offer.</li>
        <li>You hereby agree to indemnify and keep WILUMINATY TV harmless against all damages, liabilities, costs, expenses, claims, suits and proceedings (including reasonable attorney's fee) that may be suffered by WILUMINATY TV as a consequence of (i) violation of terms of this Policy by You; (ii) violation of applicable laws; (iii) any action or inaction resulting in willful misconduct or negligence on Your part.</li>
        <li>This Policy shall be governed in accordance with the applicable laws in India. Courts at Bangalore shall have the exclusive jurisdiction to settle any dispute that may arise under this Policy.</li>
        <li>In case of issues, you may reach out to our customer care center for prompt resolution but it may take up to 14 days to resolve your query.</li>
        <li>This document is an electronic record in terms of Information Technology Act, 2000, and the Rules thereunder as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record does not require any physical or digital signatures. Wherever the context so requires "You/Your/User" shall mean any user on the WILUMINATY TV Mobile App. "WILUMINATY TV"/"We"/"Our" shall mean WILUMINATY TV Fashions Private Limited.</li>
      </ol>
    </div>
  );
};

export default InviteAndEarn;
