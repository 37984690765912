// src/components/Help.js
import React, { useState } from 'react';
import styled from 'styled-components';
import './Help.css';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-top: 2em;
  padding: 20px;
  box-sizing: border-box;
`;

const Header = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em 0;
  margin-top: 7em;
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

const SearchContainer = styled.div`
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 1em 0;
`;

const ClearButton = styled.button`
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #aaa;
  transition: color 0.3s;

  &:hover {
    color: #333;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 1em;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 26px;
  box-sizing: border-box;
`;

const ResultsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 2em 0;
`;

const ResultItem = styled.div`
  padding: 1em;
  border: 1px solid #eee;
  margin: 0.5em 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const backgroundImageStyle = {
  backgroundImage: "url(/Images/orange1.jpg)", // Ensure you have the image in the public/Images folder
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  marginTop: '-9em'
};

const Help = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const mockData = [
    { id: 1, question: 'How to track my order?', answer: 'You can track your order by logging into your account and visiting the order tracking section.' },
    { id: 2, question: 'What is the return policy?', answer: 'Our return policy allows returns within 30 days of purchase with a valid receipt.' },
    { id: 3, question: 'How can I contact customer support?', answer: 'You can contact customer support via email at support@fashionwebsite.com or call us at 7276961619.' },
    // Add more mock FAQs here
  ];

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    const results = mockData.filter(item =>
      item.question.toLowerCase().includes(value.toLowerCase()) ||
      item.answer.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResults(results);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults([]);
  };

  return (
    <Container style={backgroundImageStyle}>
      <Header>
        <Title>How can we help?</Title>
      </Header>
      <SearchContainer>
        <SearchInput
          type="text"
          placeholder="Search for help..."
          value={searchTerm}
          onChange={handleSearch}
        />
        {searchTerm && <ClearButton onClick={clearSearch}>✖</ClearButton>}
      </SearchContainer>
      <ResultsContainer>
        {searchResults.map(result => (
          <ResultItem key={result.id}>
            <h3>{result.question}</h3>
            <p>{result.answer}</p>
          </ResultItem>
        ))}
      </ResultsContainer>
    </Container>
  );
};

export default Help;
