import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Donation from './components/Donation';
import Subscription from './components/Subscription';
import Sponsorship from './components/Sponsorship';
import Home from './components/Home';
import Header from './components/header';
import About from './components/About';
import Contact from './components/Contact';
import Termsanduse from './components/Termsanduse';
import Career from './components/Career';
import Help from './components/Help';
import SearchResults from './components/SearchResults';
import './components/Global.css'; // Import global styles
import Survey from './components/Survey';
import SocialMedia from './components/SocialMedia';
import ExchangePolicy from './components/ExchangePolicy';
import PromoCode from './components/PromoCode';
import Shipping from './components/Shipping';
import Privacypolicy from './components/Privacypolicy';
import InviteAndEarn from './components/InviteAndEarn';
import PaymentOption from './components/PaymentOption';
import Product from './components/Product';
import { UserProvider } from './components/UserContext';
import  PersonalInfo  from './components/PersonalInfo';
import  Model  from './components/Model';
import Magazine from './components/Magazine';
import MonthsTopper from './components/MonthsTopper';
import Top10 from './components/Top100';
import Top100 from './components/Top100';
import NewsArticle from './components/NewsArticle';
import Channel from './components/Channel';
import Login from './components/Login';
import Investment from './components/Investment';


const App = () => {
  return (
    <UserProvider>
    <Router>
      <div className='app'>
     
        <Header />
     
   <SearchResults />
        <div className="content">
          <Routes>
            <Route path="/donation" element={<Donation />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/sponsorship" element={<Sponsorship />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/" element={<Home />} />
            <Route path="/Termsanduse" element={<Termsanduse />} />
            <Route path="/career" element={<Career />} />
            <Route path="/help" element={<Help />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/SocialMedia" element={<SocialMedia />} />
            <Route path="/ExchangePolicy" element={<ExchangePolicy />} />
            <Route path="/PromoCode" element={<PromoCode />} />
            <Route path="/Shipping" element={<Shipping />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route path="/InviteAndEarn" element={<InviteAndEarn />} />
            <Route path="/PaymentOption" element={<PaymentOption />} />
            <Route path="/Product" element={<Product />} />
            <Route path="/PersonalInfo" element={<PersonalInfo />} />
            <Route path="/Model" element={<Model />} />
            <Route path="/Magazine" element={<Magazine />} />
            <Route path="/MonthsTopper" element={<MonthsTopper />} />
            <Route path="/Top10" element={<Top10/>} />
            <Route path="/Top100" element={<Top100 />} />
            <Route path="/NewsArticle" element={<NewsArticle />} />
            <Route path="/Channel" element={<Channel />} />
            <Route path="/Investment" element={<Investment />} />
            <Route path="/Home" element={<Home />} />


          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
    </UserProvider>
  );
};

export default App;
